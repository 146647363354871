import { useTranslation } from "react-i18next";

const MyCommandCell = (props: any) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];

  const { t } = useTranslation();
  return inEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
        onClick={() => props.update(dataItem)}
      >
        {t("Update")}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() => props.cancel(dataItem)}
      >
        {t("Cancel")}
      </button>
      {props.remove && (
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
          onClick={() => props.remove(dataItem)}
        >
          {t("Remove")}
        </button>
      )}
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
        onClick={() => props.edit(dataItem)}
      >
        {t("Edit")}
      </button>
    </td>
  );
};

export default MyCommandCell;
