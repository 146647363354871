import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { GetCompanies } from "../shared/apiService";
import { CompanyDto, Roles, UserDto } from "../shared/sharedModel";

import "./UserForm.scss";

const UserForm = ({ toggleDialog, handleAddUser }: any) => {
  const [email, setEmail] = useState<string>("");
  const [gaiaId, setGaiaId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [tractebelEmployee, setTractebelEmployee] = useState<boolean>(true);
  const [company, setCompany] = useState<any>();
  const [engieUserRole, setEngieUserRole] = useState<any>();
  const [isFormFilled, setIsFormFilled] = useState(true);

  const [companies, setCompanies] = useState<CompanyDto[]>([]);

  useQuery("companies", () => GetCompanies(), {
    onSuccess: (data: any) => {
      setCompanies(data.data);
    },
  });

  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if ((email || gaiaId) && firstName && lastName && engieUserRole) {
      const newUser: UserDto = {
        email: tractebelEmployee ? `${gaiaId}@engie.com` : email,
        firstName: firstName,
        lastName: lastName,
        tractebelEmployee: tractebelEmployee,
        companyId: tractebelEmployee ? null : company.id,
        engieUserRole: engieUserRole.id,
      };
      handleAddUser(newUser);
      toggleDialog();
    }
  };

  const handleChange = useCallback(
    (e: RadioButtonChangeEvent) => {
      setTractebelEmployee(e.value);
      setEngieUserRole(null);
      if (e.value === true) {
        setCompany(undefined);
      }
    },
    [setTractebelEmployee]
  );

  const handleRoleChange = (e: ComboBoxChangeEvent) => {
    const role = e.value;
    setEngieUserRole(role);
  };

  const handleCompanyChange = (e: ComboBoxChangeEvent) => {
    setCompany(e.value);
  };

  const tractebelRoles = [
    { text: t("Admin"), id: 1 },
    { text: t("SubjectSpoc"), id: 3 },
    { text: t("TeExpert"), id: 6 },
    { text: t("TeSpoc"), id: 5 },
    { text: t("Viewer"), id: 2 },
  ];

  const externalRoles = [
    { text: t("Requester"), id: 7 },
    { text: t("Validator"), id: 4 },
    { text: t("Viewer"), id: 2 },
  ];

  return (
    <div className="userForm--main">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="userForm--field">
          <label>{t("FirstName")}</label>
          <Input
            required
            onChange={(e) => {
              setFirstName(e.value);
            }}
            validityStyles={false}
          />
        </div>
        <div className="userForm--field">
          <label>{t("LastName")}</label>
          <Input
            required
            onChange={(e) => {
              setLastName(e.value);
            }}
            validityStyles={false}
          />
        </div>
        {tractebelEmployee ? (
          <div className="userForm--field">
            <label>GAIA ID (your D10 username)</label>
            <Input
              required
              onChange={(e) => {
                setGaiaId(e.value);
              }}
              valid={/^[A-Za-z0-9]+(-A)?$/.test(gaiaId as string)}
              validationMessage="GAIA ID is not an email address. Example DAA000 or DAA000-A"
              validityStyles={false}
            />
          </div>
        ) : (
          <div className="userForm--field">
            <label>E-mail</label>
            <Input
              required
              onChange={(e) => {
                setEmail(e.value);
              }}
              validityStyles={false}
            />
          </div>
        )}
        <div className="userForm--field">
          <label>{t("TractebelEmployee")}</label>
          <div className="userForm--radioButtons">
            <div>
              <RadioButton
                required
                name="group1"
                value={true}
                checked={tractebelEmployee === true}
                label={t("Yes")}
                onChange={handleChange}
              />
            </div>
            <div>
              <RadioButton
                required
                name="group1"
                value={false}
                checked={tractebelEmployee === false}
                label={t("No")}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {tractebelEmployee === false && (
          <div className="userForm--field">
            <label>{t("Company")}</label>
            <ComboBox
              required
              data={companies}
              value={company}
              textField="name"
              dataItemKey="id"
              onChange={handleCompanyChange}
              validityStyles={false}
            />
          </div>
        )}
        <div className="userForm--field">
          <label>User Role</label>
          <ComboBox
            required
            data={tractebelEmployee ? tractebelRoles : externalRoles}
            value={engieUserRole ? engieUserRole : null}
            onChange={handleRoleChange}
            validityStyles={false}
            dataItemKey="id"
            textField="text"
          />
        </div>
        <div className="companyForm--field">
          <button
            type="submit"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          >
            {t("Create")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
