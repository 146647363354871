import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
} from "@progress/kendo-react-buttons";
import { GridFilterCellProps } from "@progress/kendo-react-grid";

let date: any = "";
let filter: any = "";

const DatePickerFilterCell = (props: GridFilterCellProps) => {
  const filterList = [
    { name: "Is equal to", value: "eq" },
    { name: "After or equal", value: "gte" },
    { name: "Before or equal", value: "lte" },
  ];

  const onChange = (event: DatePickerChangeEvent) => {
    date = new Date(event.target.value!);
    props.onChange({
      value: date,
      operator: filter || "eq",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onFilter = (event: DropDownButtonItemClickEvent) => {
    filter = event.item?.value;
    props.onChange({
      value: date,
      operator: filter,
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    date = "";
    filter = "";
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell date-filter">
      <DatePicker onChange={onChange} value={props.value} format="dd-MM-yyyy" />
      <DropDownButton
        onItemClick={onFilter}
        icon="filter"
        textField="name"
        items={filterList}
      />
      <Button
        title="Clear"
        disabled={!date}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};

export default DatePickerFilterCell;
