import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";

import "./NavMenu.scss";
import { useContext, useEffect, useState } from "react";
import { Roles } from "../shared/sharedModel";
import { Dialog } from "@progress/kendo-react-dialogs";
import { UserContext } from "../UserContext/UserContext";

const NavMenu = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [isHomeActive, setIsHomeActive] = useState(false);
  const [isAdminActive, setIsAdminActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [user] = useContext(UserContext);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setIsHomeActive(true);
        setIsAdminActive(false);
        break;
      case "/admin":
        setIsAdminActive(true);
        setIsHomeActive(false);
        break;
    }
  }, [location]);

  const handleButton = (path: string) => {
    navigate(path, { replace: true });
  };

  const toggleDialog = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="navMenu-main">
      {isVisible && (
        <Dialog title={"Contact us"} onClose={toggleDialog}>
          <h3>
            If you encounter any problems while using the application, please
            send an email to
          </h3>
          <div className="navMenu-dialog--email">
            <a href="mailto:support.reacte@tractebel.engie.com">
              support.reacte@tractebel.engie.com
            </a>
          </div>
        </Dialog>
      )}
      <Button
        data-testid="navButton"
        className={`material-icons navMenu--button ${
          isHomeActive ? "navMenu--button-active" : ""
        }`}
        fillMode={"flat"}
        onClick={() => handleButton("/")}
      >
        home
      </Button>
      {user.engieUserRole === Roles.Admin && (
        <Button
          data-testid="navButton"
          className={`material-icons navMenu--button ${
            isAdminActive ? "navMenu--button-active" : ""
          }`}
          fillMode={"flat"}
          onClick={() => handleButton("/admin")}
        >
          build
        </Button>
      )}
      <Button
        data-testid="navButton"
        className={`material-icons navMenu--button`}
        fillMode={"flat"}
        onClick={() => toggleDialog()}
      >
        contact_support
      </Button>
    </div>
  );
};

export default NavMenu;
