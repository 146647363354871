import { Button } from "@progress/kendo-react-buttons";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";

export const BooleanFilterCell = (props: GridFilterCellProps) => {
  let hasValue: any = (value: any) => value !== "";

  const data = [
    { text: "True", value: true },
    { text: "False", value: false },
  ];

  const onChange = (event: DropDownListChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value.value : "",
      operator: hasValue ? "eq" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: "",
      operator: "",
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <DropDownList
        data={data}
        onChange={onChange}
        value={
          data.find((d: any) => d.value === props.value) || {
            text: "",
            value: "",
          }
        }
        textField="text"
        dataItemKey="value"
      />
      <Button
        title="Clear"
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};
