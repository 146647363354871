import { AccessToken } from "@okta/okta-auth-js";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import axios, { AxiosRequestConfig } from "axios";
import {
  CommentDto,
  CompanyDto,
  SubjectDto,
  RequestDto,
  UserDto,
  DepartmentDto,
  ShortColumnDto,
} from "./sharedModel";

let config: AxiosRequestConfig;

export const SetToken = (accessToken: AccessToken | undefined) => {
  config = {
    headers: { Authorization: "Bearer " + accessToken?.accessToken },
  };
};

export const GetUserWithOkta = (): Promise<UserDto> => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/users/current-user/`, config)
    .then((res) => {
      return res.data;
    });
};

export const GetUsers = (dataState: State): Promise<UserDto[]> => {
  const queryStr = `${toDataSourceRequestString(dataState)}`;
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/users/get-filtered-users?${queryStr}`,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const GetTeExperts = (): Promise<UserDto[]> => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/users/get-te-experts`, config)
    .then((res) => {
      return res.data;
    });
};

export const PostUser = (user: UserDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/users/create-user-with-okta`,
    user,
    config
  );
};

export const UpdateUser = (user: UserDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/users/update-user`,
    user,
    config
  );
};

export const GetRequests = (dataState: State): Promise<RequestDto[]> => {
  const queryStr = `${toDataSourceRequestString(dataState)}`;
  //const hasGroups = dataState.group && dataState.group.length
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-filtered-requests?${queryStr}`,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const GetCompanies = (dataState?: State): Promise<CompanyDto[]> => {
  const queryStr = dataState ? `${toDataSourceRequestString(dataState)}` : "";
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-filtered-companies?${queryStr}`,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const PostCompany = (company: CompanyDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/update-company`,
    company,
    config
  );
};

export const GetSubjects = (dataState?: State) => {
  const queryStr = dataState ? `${toDataSourceRequestString(dataState)}` : "";
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-filtered-subjects?${queryStr}`,
      config
    )
    .then((res) => {
      return res.data;
    });
};

export const GetActiveSubjects = () => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-active-subjects?`, config)
    .then((res) => {
      return res.data;
    });
};

export const GetSubjectsOfCompany = (companyId: number) => {
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-subjects-of-company?companyId=${companyId}`,
      config
    )
    .then((res) => res.data);
};

export const GetChildrenOfSubject = (
  subjectId?: number,
  companyId?: number
) => {
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-children-of-subject?subjectId=${subjectId}&companyId=${companyId}`,
      config
    )
    .then((res) => res.data);
};

export const UpdateSubject = (subject: SubjectDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/update-subject`,
    subject,
    config
  );
};

export const AddSubject = (newSubject: SubjectDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/add-subject`,
    newSubject,
    config
  );
};

export const PushRequests = (data: any) => {
  return axios
    .post(
      `${process.env.REACT_APP_HOST}/api/add-or-update-requests`,
      data,
      config
    )
    .then((res) => res.data);
};

export const PostRequest = (data: any) => {
  return axios
    .post(`${process.env.REACT_APP_HOST}/api/update-request-user`, data, config)
    .then((res) => res.data);
};

export const GetSubjectSpocs = () => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/users/get-subject-spocs`, config)
    .then((res) => {
      return res.data;
    });
};

export const GetAllAnswers = () => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-types-of-answer-dictionary`)
    .then((res) => res.data);
};

export const GetStatusesDictionary = () => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-statuses-dictionary`)
    .then((res) => res.data);
};
export const GetRequestId = (subjectId: number, companyId: number) => {
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-new-request-id/${subjectId}/${companyId}`
    )
    .then((res) => res.data);
};

export const GetExportToExcel = (
  stateColumns: ShortColumnDto[],
  dataState: State
): Promise<any> => {
  const queryStr = `${toDataSourceRequestString(dataState)}`;
  const columnsStr = JSON.stringify(stateColumns);
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/export-to-excel?${queryStr}&columns=${columnsStr}`,
      {
        ...config,
        responseType: "blob",
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const GetRequestById = (id: number) => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-request-by-id/${id}`, config)
    .then((res) => res.data);
};

export const GetAttachments = (requestId: number): Promise<any> => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/download-file-by-id/${requestId}`, {
      ...config,
      responseType: "blob",
    })
    .then((res) => {
      return res.data;
    });
};

export const RemoveOwnAttachment = (attachmentId: number) => {
  return axios
    .delete(
      `${process.env.REACT_APP_HOST}/api/remove-own-file/${attachmentId}`,
      config
    )
    .then((res) => res.data);
};

export const GetValidators = (id: number) => {
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/users/get-validators-from-company/${id}`,
      config
    )
    .then((res) => res.data);
};

export const GetTeSpock = () => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/users/get-te-spocs`, config)
    .then((res) => res.data);
};

export const PostComment = (data: CommentDto) => {
  return axios
    .post(`${process.env.REACT_APP_HOST}/api/save-comment`, data, config)
    .then((res) => res.data);
};

export const GetCommentsByRequestId = (requestId: number) => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-comments/${requestId}`, config)
    .then((res) => res.data);
};

export const RemoveComment = (commentId: string) => {
  return axios
    .delete(
      `${process.env.REACT_APP_HOST}/api/delete-comment/${commentId}`,
      config
    )
    .then((res) => res.data);
};

export const GetRequestChangeDates = (requestId: number) => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-changes/${requestId}`, config)
    .then((res) => res.data);
};

export const GetCompanyReferences = (companyId: number) => {
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-references/${companyId}`,
      config
    )
    .then((res) => res.data);
};

export const AddDepartment = (newDepartment: DepartmentDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/add-department`,
    newDepartment,
    config
  );
};

export const UpdateDepartment = (department: DepartmentDto) => {
  return axios.post(
    `${process.env.REACT_APP_HOST}/api/update-department`,
    department,
    config
  );
};

export const GetDepartmentsOfCompany = (companyId: number) => {
  return axios
    .get(
      `${process.env.REACT_APP_HOST}/api/get-departments-for-company?companyId=${companyId}`,
      config
    )
    .then((res) => res.data);
};

export const GetCurrentView = () => {
  return axios
    .get(`${process.env.REACT_APP_HOST}/api/get-favorite-view`, config)
    .then((res) => res.data);
};

export const UpdateCurrentView = (viewJson: any) => {
  return axios
    .post(
      `${process.env.REACT_APP_HOST}/api/update-favorite-view`,
      { name: "default", JSON: JSON.stringify(viewJson) },
      config
    )
    .then((res) => res.data);
};

export const UpdateSubjectOfTheRequest = (
  requestId: number,
  subjectId: number,
  companyId: number
) => {
  return axios
    .post(
      `${process.env.REACT_APP_HOST}/api/change-request-subject/${requestId}/${subjectId}/${companyId}`,
      {},
      config
    )
    .then((res) => res.data);
};
