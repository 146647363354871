import { Input } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DepartmentDto } from "../../shared/sharedModel";

export const DepartmentForm = ({
  toggleDialog,
  handleAddDepartment,
  selectedCompany,
}: any) => {
  const [departmentName, setDepartmentName] = useState<string>("");

  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newDepartment: DepartmentDto = {
      name: departmentName,
      active: true,
      companyId: selectedCompany.id,
    };
    handleAddDepartment(newDepartment);
    toggleDialog();
  };

  return (
    <div className="companyForm--main">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="companyForm--field">
          <label>{t("DepartmentName")}</label>
          <Input
            required
            onChange={(e) => {
              setDepartmentName(e.value);
            }}
            validityStyles={false}
          />
        </div>
        <div className="companyForm--field">
          <button
            type="submit"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          >
            {t("Create")}
          </button>
        </div>
      </form>
    </div>
  );
};
