import * as React from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { Roles } from "../../shared/sharedModel";
import { useTranslation } from "react-i18next";

export const UsersDropDownRolesCell = (props: GridCellProps) => {
  const { t } = useTranslation();

  const roles = [
    { text: t("Unassigned"), value: 0 },
    { text: t("Admin"), value: 1 },
    { text: t("Viewer"), value: 2 },
    { text: t("SubjectSpoc"), value: 3 },
    { text: t("Validator"), value: 4 },
    { text: t("TeSpoc"), value: 5 },
    { text: t("TeExpert"), value: 6 },
    { text: t("Requester"), value: 7 },
  ];

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? (
        <DropDownList
          style={{ width: "150px" }}
          onChange={handleChange}
          value={roles.find((c) => c.value === dataValue)}
          data={roles}
          textField={"text"}
        />
      ) : (
        t(Roles[dataValue])
      )}
    </td>
  );
};
