import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { useState } from "react";

import "../AdminPanel.scss";
import "../../shared/css/grids.scss";
import { Dialog } from "@progress/kendo-react-dialogs";
import CompanyForm from "../../CompanyForm/CompanyForm";
import { useMutation, useQuery } from "react-query";
import { process, State } from "@progress/kendo-data-query";
import { CompanyDto } from "../../shared/sharedModel";
import { GetCompanies, PostCompany } from "../../shared/apiService";
import MyCommandCell from "../MyCommandCell";
import { Loader } from "@progress/kendo-react-indicators";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CompaniesDropDownWDApplicable } from "./CompaniesDropDownWDApplicable";

const initialDataState: State = {
  sort: [],
  take: 10,
  skip: 0,
};

const Companies = () => {
  const [visible, setVisible] = useState(false);
  const [companiesDataState, setCompaniesDataState] =
    useState(initialDataState);

  const { t } = useTranslation();

  const [companiesData, setCompaniesData] = useState<CompanyDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const editField: string = "inEdit";

  const companies = useQuery<any, Error>(
    ["companies", companiesDataState],
    () => GetCompanies(companiesDataState),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCompaniesData(data.data);
        setIsLoading(false);
      },
    }
  );

  const postCompany = useMutation(PostCompany, {
    onSuccess: () => {
      companies.refetch();
      toast.success("Company saved!");
    },
    onError: () => {
      companies.refetch();
      toast.error(`Company couldn't be saved`);
    },
  });

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleAddCompany = (newCompany: any) => {
    postCompany.mutate(newCompany);
  };

  const enterEdit = (dataItem: any) => {
    setCompaniesData(
      companiesData.map((comp) =>
        comp.id === dataItem.id ? { ...comp, inEdit: true } : comp
      )
    );
  };

  const discard = () => {
    const data = [...companiesData];
    data.splice(0, 1);
    setCompaniesData(data);
  };

  const update = (dataItem: any) => {
    dataItem.inEdit = false;
    handleAddCompany(dataItem);
  };

  const cancel = (dataItem: any) => {
    const originalCompany = companies.data.data.find(
      (comp: CompanyDto) => comp.id === dataItem.id
    );
    if (originalCompany) {
      originalCompany.inEdit = false;
    }
    const data = companiesData.map((comp) => {
      if (comp.id) {
        if (comp.id === originalCompany?.id) {
          return originalCompany;
        } else {
          return comp;
        }
      } else {
        if (comp.trigram === originalCompany?.trigram) {
          return originalCompany;
        } else {
          return comp;
        }
      }
    });
    if (data) setCompaniesData(data);
  };

  const CommandCell = (props: GridCellProps) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const itemChange = (event: GridItemChangeEvent) => {
    const data = companiesData.map((comp) =>
      comp.id === event.dataItem.id
        ? { ...comp, [event.field || ""]: event.value }
        : comp
    );
    setCompaniesData(data);
  };

  return (
    <div className="adminPanel--container">
      {isLoading ? (
        <div className="adminPanel--loading">
          <Loader type="pulsing" size="large" />
        </div>
      ) : (
        <div>
          {visible && (
            <Dialog title={t("AddNewCompany")} onClose={toggleDialog}>
              <CompanyForm
                toggleDialog={toggleDialog}
                handleAddCompany={handleAddCompany}
              />
            </Dialog>
          )}
          <Grid
            className="users--grid"
            data={process(companiesData, companiesDataState)}
            {...companiesDataState}
            resizable={true}
            sortable={true}
            filterable={true}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setCompaniesDataState(e.dataState);
            }}
            editField={editField}
            onItemChange={itemChange}
            onPageChange={(e) => {
              if (companiesDataState.skip !== e.page.skip) {
                setIsLoading(true);
                let newCompaniesDataState = companiesDataState;
                newCompaniesDataState.skip = e.page.skip;
                newCompaniesDataState.take = e.page.take;
                setCompaniesDataState(newCompaniesDataState);
              }
            }}
          >
            <GridToolbar>
              <div>
                <button
                  title={t("AddCompany")}
                  className="admin-add--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={toggleDialog}
                >
                  {t("AddCompany")}
                </button>
              </div>
            </GridToolbar>
            <GridColumn field="name" title={t("Name")} />
            <GridColumn field="trigram" title="Trigram" />
            <GridColumn
              field="hourlyRateForPriority1"
              title={t("NormalRate")}
            />
            <GridColumn
              field="hourlyRateForPriority2"
              title={t("ImportantRate")}
            />
            <GridColumn
              field="hourlyRateForPriority3"
              title={t("CriticalRate")}
            />
            <GridColumn
              field="isWDApplicable"
              title={t("IsWDApplicable")}
              cell={CompaniesDropDownWDApplicable}
            />
            <GridColumn field="pmEmail" title={t("PMEmail")} />
            <GridColumn
              width="100px"
              cell={CommandCell}
              filterable={false}
              sortable={false}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Companies;
