import { useState } from "react";
import { CompanyDto } from "../../shared/sharedModel";
import { useQuery } from "react-query";
import { GetCompanies, GetDepartmentsOfCompany } from "../../shared/apiService";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import Departments from "./Departments";

const SelectCompanyOfDepartments = () => {
  const [departments, setDepartments] = useState([]);

  const [companies, setCompanies] = useState<CompanyDto[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<CompanyDto>();

  useQuery<any, Error>("companies", () => GetCompanies(), {
    onSuccess: (data) => setCompanies(data.data),
  });

  const departmentsQuery = useQuery(
    ["departments", selectedCompany?.id],
    () => GetDepartmentsOfCompany(selectedCompany?.id!),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
      onSuccess: (data) => {
        setDepartments(data);
      },
    }
  );

  return (
    <div>
      <div>
        {!selectedCompany && <h3>Please select a company:</h3>}
        <DropDownList
          data={companies}
          onChange={(e) => setSelectedCompany(e.value)}
          textField="name"
          dataItemKey="id"
          value={selectedCompany}
          style={{ width: "400px" }}
        />
        {selectedCompany &&
          (departmentsQuery.isLoading ? (
            <div className="subjectTree--loading">
              <Loader type="pulsing" size="large" />
            </div>
          ) : (
            <Departments
              selectedCompany={selectedCompany}
              departments={departments}
              departmentsQuery={departmentsQuery}
            />
          ))}
      </div>
    </div>
  );
};

export default SelectCompanyOfDepartments;
