import { TextArea } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { PushRequests } from "../shared/apiService";
import { RequestDto, RequestStatus } from "../shared/sharedModel";

export const Rejection = (props: any) => {
  const rejectRequest = useMutation(PushRequests, {
    onSuccess: () => {
      toast.success("Request rejected");
      props.toggleDialog();
      props.getRequestById.refetch();
    },
    onError: () => {
      toast.error(`Request couldn't be rejected`);
    },
  });

  const [rejectionReason, setRejectionReason] = useState<string>("");

  const handleRejection = () => {
    if (
      props.requestBody.setStatusDto.currentStatus === RequestStatus.Reviewing
    ) {
      props.requestBody.setStatusDto.futureStatus =
        RequestStatus.RejectedByTractebel;
    } else if (
      props.requestBody.setStatusDto.currentStatus === RequestStatus.Finished
    ) {
      props.requestBody.setStatusDto.futureStatus = RequestStatus.InProgress;
    } else if (
      props.requestBody.setStatusDto.currentStatus === RequestStatus.Estimated
    ) {
      props.requestBody.setStatusDto.futureStatus =
        RequestStatus.RejectedByClient;
    } else if (
      props.requestBody.setStatusDto.currentStatus === RequestStatus.InProgress
    ) {
      props.requestBody.setStatusDto.futureStatus = RequestStatus.Revision;
    } else {
      props.requestBody.setStatusDto.futureStatus =
        props.requestBody.setStatusDto.currentStatus - 1;
    }

    if (rejectionReason !== "") {
      props.requestBody.rejectionReason = rejectionReason;

      rejectRequest.mutate(props.requestBody);
    } else {
      toast.error("Cannot leave rejection reason empty");
    }
  };

  return (
    <div>
      <p>Are you sure you want to reject this request</p>
      <p>Write the reason for rejection</p>
      <TextArea
        value={rejectionReason}
        onChange={(e) => {
          setRejectionReason(e.value);
        }}
      />
      <div className="dialog-buttons">
        <button onClick={handleRejection}>Yes</button>
        <button onClick={props.toggleDialog}>No</button>
      </div>
    </div>
  );
};
