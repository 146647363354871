import { useOktaAuth } from "@okta/okta-react";
import { Button } from "@progress/kendo-react-buttons";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../UserContext/UserContext";
import i18n from "../i18n";
import "./TopBar.scss";
import { Switch } from "@progress/kendo-react-inputs";
import { useMutation } from "react-query";
import { UpdateUser } from "../shared/apiService";
import { toast } from "react-toastify";
import { Dialog } from "@progress/kendo-react-dialogs";
import { VacationForm } from "../VacationForm/VacationForm";
import { useTranslation } from "react-i18next";
import TractebelLogo from "../assets/tractebel_logo.jpg";
import WeActLogo from "../assets/weact_logo.jpg";

const TopBar = () => {
  const languages = ["en", "fr", "pl", "nl"];

  const { oktaAuth } = useOktaAuth();

  const [user] = useContext(UserContext);

  const [isVacation, setIsVacation] = useState(user.isOnVacation);

  const [isVisible, setIsVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isVacationDialogVisible, setIsVacationDialogVisible] = useState(false);

  useEffect(() => {
    if (user.isOnVacation && user.vacationTo) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const vacationTo = new Date(user.vacationTo);
      if (vacationTo && vacationTo < today) {
        const updatedUser = { ...user, isOnVacation: false };
        updateUser.mutate(updatedUser);
        setIsVacation(false);
      }
    }
  }, []);

  const logOut = () => oktaAuth?.signOut();

  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsVisible(!isVisible);
  };

  const handleChangeLanguage = (event: DropDownListChangeEvent) => {
    i18n.changeLanguage(event.value);
    setCurrentLanguage(event.value);
  };

  const updateUser = useMutation(UpdateUser, {
    onSuccess: () => {
      toast.success("Updated!");
    },
  });

  const handleChangeVacationMode = (event: any) => {
    if (event.value) {
      setIsVacationDialogVisible(true);
    } else {
      const updatedUser = { ...user, isOnVacation: false };
      updateUser.mutate(updatedUser);
      setIsVacation(false);
    }
  };

  const handleSubmitDialog = (from: Date, to: Date) => {
    setIsVacationDialogVisible(false);
    from.setHours(from.getHours() - from.getTimezoneOffset() / 60);
    to.setHours(to.getHours() - to.getTimezoneOffset() / 60);
    const updatedUser = {
      ...user,
      isOnVacation: true,
      vacationFrom: from,
      vacationTo: to,
    };
    updateUser.mutate(updatedUser);
    setIsVacation(true);
  };

  return (
    <div className="topBar--main">
      {isVacationDialogVisible && (
        <Dialog
          title={t("SetVacationPeriod")}
          onClose={() => setIsVacationDialogVisible(false)}
        >
          <VacationForm onSubmitDialog={handleSubmitDialog} />
        </Dialog>
      )}
      <div className="topBar--applicationName">
        <img src={WeActLogo} height={32} alt="weact logo" />
      </div>
      <div className="topBar--applicationOptions">
        {!user ? (
          <div></div>
        ) : (
          <div className="topBar--userContainer">
            {user.firstName ? (
              <div>
                <Button
                  data-testid="dropdownButton"
                  onClick={toggleDropdown}
                  fillMode="flat"
                >
                  <div className="topBar--userInfo">
                    {" "}
                    <span
                      className={`material-icons ${
                        isVisible ? "topBar-icon--up" : "topBar-icon--down"
                      }`}
                    >
                      expand_more
                    </span>{" "}
                    <span data-testid="username">
                      {user.firstName} {user.lastName}
                    </span>
                  </div>
                </Button>
                {isVisible && (
                  <div className="topBar--dropDown">
                    <Button
                      data-testid="logout"
                      onClick={logOut}
                      fillMode="flat"
                    >
                      Log out
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <span data-testid="username">No username</span>
            )}
          </div>
        )}
        {user.engieUserRole !== 2 && (
          <div className="topBar--userContainer">
            <span className="topBar--userContainer">Vacation Mode</span>
            <Switch
              onChange={handleChangeVacationMode}
              checked={isVacation}
              onLabel={""}
              offLabel={""}
            />
          </div>
        )}
        <div className="topBar--languageInfo">
          <DropDownList
            fillMode={"flat"}
            data={languages}
            value={currentLanguage}
            onChange={handleChangeLanguage}
          />
        </div>
        <img
          src={TractebelLogo}
          className="topBar--logo"
          alt="tractebel logo"
        />
      </div>
    </div>
  );
};

export default TopBar;
