import * as React from "react";
import { useRef, useState } from "react";
import {
  CompanyDto,
  SubjectDto,
  RequestDto,
  Roles,
  UserDto,
  DepartmentDto,
} from "../shared/sharedModel";
import "./RequestForm.scss";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  DropDownList,
} from "@progress/kendo-react-dropdowns";
import {
  Checkbox,
  Input,
  Switch,
  TextArea,
} from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import {
  GetAllAnswers,
  PushRequests,
  GetRequestId,
  GetUserWithOkta,
  GetValidators,
  GetCompanies,
  GetActiveSubjects,
  GetCompanyReferences,
  GetDepartmentsOfCompany,
} from "../shared/apiService";
import { useQuery } from "react-query";
import { Upload } from "@progress/kendo-react-upload";
import {
  Form,
  Field,
  FormElement,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Stepper } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { useTranslation } from "react-i18next";
import {
  processTreeViewItems,
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
} from "@progress/kendo-react-treeview";
import { toast } from "react-toastify";
import { checkForVacation } from "./vacationModeHandlers";
import { priorityLevels } from "../shared/priorityLevels";

const CreateRequestForm = () => {
  const { t, i18n } = useTranslation();

  const steps = [
    { label: t("SelectSubject"), isValid: undefined },
    { label: t("AdditionalInformation"), isValid: undefined },
  ];

  const [requestId, setRequestId] = useState("");
  const [step, setStep] = useState(0);
  const [requester, setRequester] = useState<UserDto>();
  const [answersList, setAnswersList] = useState([]);
  const [answersState, setAnswersState] = useState<number[]>([]);
  const [priorityLevel, setPriorityLevel] = useState<any>();
  const [referenceType, setReferenceType] = useState<any>();
  const referenceContent = useRef<string>("");
  const [referenceCheckboxStatus, setReferenceCheckboxStatus] =
    useState<boolean>(false);
  const [seismicQuestions, setSeismicQuestions] = useState<boolean>(false);

  const [subject, setSubject] = useState<SubjectDto | null>(null);
  const [subjects, setSubjects] = useState<SubjectDto[]>([]);

  const [departments, setDepartments] = useState<DepartmentDto[]>();
  const [department, setDepartment] = useState<DepartmentDto>();

  const [companies, setCompanies] = useState<CompanyDto[]>([]);

  const [select, setSelect] = useState([""]);

  const [expand, setExpand] = React.useState({
    ids: [""],
    idField: "text",
  });

  const [requestCompany, setRequestCompany] = useState<
    CompanyDto | undefined
  >();

  const [attachments, setAttachments] = useState<any>([]);
  const [validator, setValidator] = useState<UserDto>();
  const [isSending, setIsSending] = useState<boolean>(false);

  const companiesQuery = useQuery<any, Error>(
    "companies",
    () => GetCompanies(),
    {
      onSuccess: (data) => setCompanies(data.data),
    }
  );

  const user = useQuery<UserDto, Error>(["userOkta"], GetUserWithOkta, {
    enabled: companies.length > 0,
    onSuccess: (data) => {
      setRequester(data);
      if (data && data.engieUserRole !== Roles.Admin) {
        setRequestCompany(
          companies.find((c: CompanyDto) => c.id === data.companyId)
        );
      }
    },
  });

  const referenceTypes = useQuery<any, Error>(
    ["references", requestCompany?.id],
    () => GetCompanyReferences(requestCompany?.id!),
    {
      enabled: !!requestCompany,
    }
  );

  let navigate = useNavigate();

  const validators = useQuery<any, Error>(
    ["validators", requestCompany?.id],
    () => GetValidators(requestCompany?.id!),
    {
      enabled: !!requestCompany,
    }
  );

  useQuery("all answers", GetAllAnswers, {
    onSuccess: (data) => setAnswersList(data),
  });

  const subjectRequest = useQuery<any, Error>(
    "active-subjects",
    () => GetActiveSubjects(),
    {
      onSuccess: (data) => {
        const subItemIds: number[] = [];
        if (data.length !== 0) {
          data.forEach((sub: SubjectDto) => {
            if (sub.childSubject) {
              sub.items = sub.childSubject;
              sub.items.forEach((child) => {
                if (child.childSubject) {
                  child.items = child.childSubject;
                  child.items.forEach((grandChild) => {
                    if (grandChild.childSubject) {
                      grandChild.items = grandChild.childSubject;
                    }
                  });
                }
              });
            }
            if (sub.subjectEntityId) {
              subItemIds.push(sub.id);
            }
          });
          const filteredData = data.filter(
            (d: SubjectDto) => !subItemIds.includes(d.id)
          );
          filteredData.sort((sub1: SubjectDto, sub2: SubjectDto) =>
            sub2.name !== "Other" ? sub1.subjectOrder - sub2.subjectOrder : -1
          );
          setSubjects(filteredData);
        }
      },
    }
  );

  const idRequest = useQuery(
    ["get request id", subject?.id, requestCompany?.id],
    () => GetRequestId(subject?.id!, requestCompany?.id!),
    {
      enabled: !!subject?.id && !!requestCompany?.id,
      onSuccess: (data) => setRequestId(data),
    }
  );

  const departmentsQuery = useQuery(
    ["departments", requestCompany?.id],
    () => GetDepartmentsOfCompany(requestCompany?.id!),
    {
      refetchOnWindowFocus: false,
      enabled: !!requestCompany,
      onSuccess: (data) => {
        const depStatus = data.filter(
          (element: DepartmentDto) => element.active
        );
        setDepartments(depStatus);
      },
    }
  );

  const handleValidation = (request: RequestDto) => {
    if (!request.title) {
      toast.error("You have to provide the title of a request!");
      return false;
    }
    if (!request.description) {
      toast.error("You have to provide a description!");
      return false;
    }
    if (!request.priorityLevel) {
      toast.error("You have to choose a priority level!");
      return false;
    }
    if (!request.companyId) {
      toast.error("You have to choose a company!");
      return false;
    }
    if (!request.validatorApplicationUserId) {
      toast.error("You have to choose a validator!");
      return false;
    }
    if (!request.deadline) {
      toast.error("You have to specify a deadline!");
      return false;
    }
    if (!request.priorityLevel) {
      toast.error("You have to choose a priority level!");
      return false;
    }
    if (request.selectedTypesOfAnswer.length <= 0) {
      toast.error("You have to select at least one answer!");
      return false;
    }
    const company = companies.find((c) => c.id === request.companyId);
    const companySubject = company?.companySubjects.find(
      (sub) => sub.subjectId === request.subjectId
    );
    if (!companySubject?.subjectSpocApplicationUserId) {
      toast.error(
        `Cannot create request: This subject doesn't have a Subject Spoc chosen, please contact administration.`
      );
      return false;
    }
    if (
      request.requestInTheNameOf?.length! >= 1 &&
      !/^[a-zA-Z]+\s[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$|^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
        request.requestInTheNameOf as string
      )
    ) {
      toast.error(
        "In the 'Request in the Name of' field, please enter a full name or email address"
      );
      return false;
    }
    if (request.requestInTheNameOf?.length! >= 200) {
      toast.error(
        "In the 'Request in the Name of' field, value has to be shorter then 200 characters"
      );
      return false;
    }
    return true;
  };

  const submitRequest = (event: any) => {
    const selectedAnswers: any = [];
    answersState.forEach((element: number) => {
      selectedAnswers.push({ typeOfAnswerDictionaryId: element });
    });
    let requestDeadline = event.deadline;
    if (event.deadline) {
      if (event.deadline.getMonth() + 1 < 10) {
        requestDeadline = `${event.deadline.getFullYear()}-0${
          event.deadline.getMonth() + 1
        }`;
      } else {
        requestDeadline = `${event.deadline.getFullYear()}-${
          event.deadline.getMonth() + 1
        }`;
      }
      if (event.deadline.getDate() < 10) {
        requestDeadline =
          requestDeadline + `-0${event.deadline.getDate()}T00:00:00.000Z`;
      } else {
        requestDeadline =
          requestDeadline + `-${event.deadline.getDate()}T00:00:00.000Z`;
      }
    }

    const request: RequestDto = {
      requestId: requestId,
      title: event.title,
      description: event.description,
      requesterApplicationUserId: requester?.id!,
      validatorApplicationUserId: validator?.id!,
      subjectId: subject?.id || 8,
      companyId:
        requester?.engieUserRole === Roles.Admin
          ? requestCompany?.id
          : requester?.companyId!,
      deadline: requestDeadline,
      priorityLevel: priorityLevel?.id,
      wdNumber: event.wdNumber,
      projectNumber: event.projectNumber,
      selectedTypesOfAnswer: selectedAnswers,
      attachments: attachments.map((attachment: any) => {
        return {
          originalFileName: attachment.originalFileName,
          fileNameWithTimeStampPrefix: attachment.fileNameWithTimeStampPrefix,
          guid: attachment.guid,
          id: attachment.id,
        };
      }),
      otherReason: answersState.includes(5) ? event.otherReason : "",
      setStatusDto: {
        requestId: requestId,
        currentStatus: 0,
        futureStatus: 0,
      },
      requestInTheNameOf: event.requestInTheNameOf || "",
      referenceNumber: event.referenceNumber,
      referenceCheckbox:
        referenceType?.referenceCheckboxLabel && referenceCheckboxStatus,
      referenceId: referenceType?.id || null,
      isSeismic: seismicQuestions,
      departmentId: department?.id!,
      budgetOwner: event.budgetOwner!,
    };
    if (handleValidation(request)) {
      setIsSending(true);
      PushRequests(request).then(() => {
        setIsSending(false);
        toast.success("Request created");
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 2000);
      });
    }
  };

  const addAttachmentsHandler = (event: any) => {
    event.response?.response?.map((element: any) => {
      setAttachments((prevAttachments: any) => [
        ...prevAttachments,
        {
          originalFileName: element.originalFileName,
          fileNameWithTimeStampPrefix: element.fileNameWithTimeStampPrefix,
          guid: element.guid,
          id: element.id,
          removeUid: event.affectedFiles[0].uid,
        },
      ]);
    });
  };

  const removeAttachmentHandler = (event: any) => {
    const attachmentState = attachments.filter(
      (attachment: any) => event.affectedFiles[0].uid !== attachment.removeUid
    );
    setAttachments(attachmentState);
  };

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.name);
    index === -1 ? ids.push(event.item.name) : ids.splice(index, 1);
    setExpand({ ids, idField: "name" });
  };

  const onItemClick = (event: TreeViewItemClickEvent) => {
    if (event.item) {
      if (event.item.childSubject.length <= 0) {
        setSubject(event.item);
        setSelect([event.itemHierarchicalIndex]);
      } else {
        toast.info(t("PleaseChooseTheLowestLevel"));
      }
    } else {
      return;
    }
  };

  const handleNextStep = () => {
    if (user.data?.engieUserRole !== Roles.Admin) idRequest.refetch();
    setStep(1);
  };

  const handleSetValidator = (event: ComboBoxChangeEvent) => {
    setValidator(event.value);
    if (event.value) checkForVacation(event);
  };

  const IdInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input value={requestId} required={true} readOnly={true} {...others} />
  );
  const TitleInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => <Input {...others} />;
  const DescriptionTextArea = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => <TextArea {...others} />;
  const RequesterInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input
      required={true}
      value={user?.data?.fullName}
      readOnly={true}
      {...others}
    />
  );
  const DepartmentComboBox = ({
    validationMessage,
    visited,
    onChange,
    value,
    ...others
  }: FieldRenderProps) => (
    <ComboBox
      data={departments}
      textField="name"
      onChange={(e) => {
        setDepartment(e.value);
      }}
      value={department}
      {...others}
    />
  );
  const RequestInTheNameOfInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => <Input {...others} />;
  const CompanyComboBox = ({
    validationMessage,
    visited,
    onChange,
    value,
    ...others
  }: FieldRenderProps) => (
    <ComboBox
      data={companies}
      textField="name"
      onChange={(e) => {
        setDepartment(undefined);
        setReferenceType(undefined);
        setReferenceCheckboxStatus(false);
        setRequestCompany(e.value);
        setValidator(undefined);
        referenceContent.current = "";
      }}
      value={requestCompany}
      {...others}
    />
  );
  const ValidatorComboBox = ({
    validationMessage,
    visited,
    onChange,
    value,
    ...others
  }: FieldRenderProps) => (
    <ComboBox
      required={true}
      data={validators.data}
      textField="fullName"
      onChange={(e) => handleSetValidator(e)}
      value={validator}
      {...others}
    />
  );
  const BudgetOwnerInput = ({
    validationMessage,
    ...others
  }: FieldRenderProps) => <Input maxLength={200} type="email" {...others} />;
  const SubjectInput = ({
    validationMessage,
    visited,
    value,
    ...others
  }: FieldRenderProps) => (
    <Input required={true} readOnly={true} value={subject?.name} {...others} />
  );
  const WdNumberInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => <Input {...others} />;
  const ProjectNumberInput = ({
    validationMessage,
    visited,
    ...others
  }: FieldRenderProps) => <Input {...others} />;
  const ReferenceDropdownList = ({
    validationMessage,
    value,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <DropDownList
      data={referenceTypes.data}
      textField="name"
      onChange={(e) => {
        setReferenceCheckboxStatus(false);
        setReferenceType(e.value);
        referenceContent.current = "";
      }}
      value={referenceType}
      {...others}
    />
  );
  const ReferenceInput = ({
    validationMessage,
    placeholder,
    value,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <Input
      value={referenceContent.current}
      onChange={(event) => {
        referenceContent.current = event.value;
        onChange({ value: referenceContent.current });
      }}
      placeholder="Please enter your reference"
      {...others}
    />
  );
  const ReferenceCheckbox = ({
    validationMessage,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <Checkbox
      checked={referenceCheckboxStatus}
      onChange={() => setReferenceCheckboxStatus((status) => !status)}
      {...others}
    />
  );
  const SeismicQuestionsSwitch = ({
    validationMessage,
    value,
    name,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <Switch
      name={name}
      checked={seismicQuestions}
      onChange={() => setSeismicQuestions((status) => !status)}
      {...others}
    />
  );
  const DeadlineDatePicker = ({
    validationMessage,
    value,
    ...others
  }: FieldRenderProps) => (
    <DatePicker
      value={value}
      min={new Date()}
      format="dd-MM-yyyy"
      {...others}
    />
  );
  const PriorityDropDownList = ({
    validationMessage,
    visited,
    value,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <DropDownList
      data={priorityLevels}
      textField="value"
      onChange={(e) => setPriorityLevel(e.value)}
      value={priorityLevel}
      {...others}
    />
  );
  const AnswerSwitch = ({
    validationMessage,
    name,
    onChange,
    ...others
  }: FieldRenderProps) => (
    <Switch
      name={name}
      checked={answersState.includes(+name)}
      onChange={(event) => {
        setAnswersState((prevState: any) => {
          let tempAnswersState = prevState;
          event.value
            ? tempAnswersState.push(+name)
            : (tempAnswersState = tempAnswersState.filter(
                (element: any) => element != +name
              ));
          return tempAnswersState;
        });
        onChange(event);
      }}
      {...others}
    />
  );

  const getDescriptionByLanguage = (subject: SubjectDto) => {
    return (
      subject?.descriptions.find(
        (element) => element?.language === i18n.language
      )?.description ||
      subject?.descriptions.find((element) => element?.language === "en")
        ?.description
    );
  };

  return (
    <div className="createRequest-form">
      <Form
        onSubmitClick={(event) => submitRequest(event.values)}
        render={() => (
          <FormElement className="main-container">
            <Stepper value={step} items={steps} />
            {step === 0 &&
              (subjects.length === 0 || subjectRequest.isLoading ? (
                <div className="requestForm--loading">
                  <Loader type="pulsing" size="large" />
                </div>
              ) : (
                <div className="subject-container">
                  <div className="subjects-section">
                    <h2 className="subject-heading">
                      {t("PleaseSelectAMainSubject")}:
                    </h2>
                    <div className="treeview-container">
                      <TreeView
                        className="treeview"
                        data={processTreeViewItems(subjects, {
                          select: select,
                          expand: expand,
                        })}
                        textField="name"
                        expandIcons={true}
                        onExpandChange={onExpandChange}
                        onItemClick={onItemClick}
                        data-testid="treeview"
                      />
                      <div className="subject-description ">
                        <h3 className="description-heading">{subject?.name}</h3>
                        <span className="description-content">
                          {getDescriptionByLanguage(subject!)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="button-container">
                    <Button type="button" onClick={() => navigate("/")}>
                      {t("Back")}
                    </Button>
                    <Button
                      data-testid="nextStep"
                      type="button"
                      disabled={!subject}
                      onClick={handleNextStep}
                    >
                      {t("NextStep")}
                    </Button>
                  </div>
                </div>
              ))}
            {step === 1 &&
              ((answersList && answersList.length === 0) || isSending ? (
                <div className="requestForm--loading">
                  <Loader type="pulsing" size="large" />
                </div>
              ) : (
                <>
                  <div className="form-container" data-testid="formContainer">
                    <div className="group-container">
                      <div className="form-item">
                        <Label editorId={"id"}>ID</Label>
                        <Field name={"id"} component={IdInput} />
                      </div>
                      <div className="form-item">
                        <Label editorId={"title"}>*{t("Title")}</Label>
                        <Field name={"title"} component={TitleInput} />
                      </div>
                      <div className="form-item">
                        <Label editorId={"description"}>
                          *{t("Description")}
                        </Label>
                        <Field
                          name={"description"}
                          component={DescriptionTextArea}
                        />
                      </div>
                      <div className="form-item">
                        <Label editorId={"requester"}>{t("Requester")}</Label>
                        <Field name={"requester"} component={RequesterInput} />
                      </div>
                      {departments && (
                        <div className="form-item">
                          <Label editorId={"Department"}>
                            {t("Department")}
                          </Label>
                          <Field
                            name={"Department"}
                            component={DepartmentComboBox}
                          />
                        </div>
                      )}
                      <div className="form-item">
                        <Label editorId={"requestInTheNameOf"}>
                          {t("RequestInTheNameOf")}
                        </Label>
                        <Field
                          name={"requestInTheNameOf"}
                          component={RequestInTheNameOfInput}
                        />
                      </div>
                      {requester?.engieUserRole === Roles.Admin && (
                        <div className="form-item">
                          <Label editorId={"Company"}>*{t("Company")}</Label>
                          <Field name={"company"} component={CompanyComboBox} />
                        </div>
                      )}

                      <div className="form-item">
                        <Label editorId={"validator"}>*{t("Validator")}</Label>
                        <Field
                          name={"validator"}
                          component={ValidatorComboBox}
                        />
                      </div>
                      {/* temporary hidden */}
                      {false && (
                        <div className="form-item">
                          <Label editorId={"budgetOwner"}>
                            {t("BudgetOwner")}
                          </Label>
                          <Field
                            name={"budgetOwner"}
                            component={BudgetOwnerInput}
                          />
                        </div>
                      )}
                    </div>
                    <div className="group-container">
                      <div className="form-item">
                        <Label editorId={"subject"}>{t("Subject")}</Label>
                        <Field name={"subject"} component={SubjectInput} />
                      </div>
                      {requestCompany?.isWDApplicable && (
                        <div className="form-item">
                          <Label editorId={"wdNumber"}>{t("WDNumber")}</Label>
                          <Field name={"wdNumber"} component={WdNumberInput} />
                        </div>
                      )}
                      <div className="form-item">
                        <Label editorId={"projectNumber"}>
                          {t("ProjectNumber")}
                        </Label>
                        <Field
                          name={"projectNumber"}
                          component={ProjectNumberInput}
                        />
                      </div>
                      <div className="form-item">
                        <div className="toggle-item">
                          <Label editorId={"seismicQuestions"}>
                            {t("SeismicQuestions")}
                          </Label>
                          <Field
                            name={"seismicQuestions"}
                            component={SeismicQuestionsSwitch}
                          />
                        </div>
                        {seismicQuestions && (
                          <span>
                            Don't forget to attach the file specific for seismic
                            topics if you have one
                            <br />
                            Doel : SAP DMS 10011197338
                            <br />
                            Tihange : XXXXXXX
                          </span>
                        )}
                      </div>
                      {referenceTypes.data?.length > 1 && (
                        <div className="form-item">
                          <Label editorId={"typeOfReference"}>
                            {t("TypeOfReference")}
                          </Label>
                          <Field
                            name={"typeOfReference"}
                            component={ReferenceDropdownList}
                          />
                        </div>
                      )}
                      {referenceType && (
                        <div className="form-item">
                          <Field
                            name={"referenceNumber"}
                            component={ReferenceInput}
                          />
                        </div>
                      )}
                      {referenceType?.referenceCheckboxLabel && (
                        <div className="checkbox-item">
                          <Label editorId={"referenceCheckbox"}>
                            {referenceType?.referenceCheckboxLabel}
                          </Label>
                          <Field
                            name={"referenceCheckbox"}
                            component={ReferenceCheckbox}
                          />
                        </div>
                      )}
                    </div>
                    <div className="group-container">
                      <div className="form-item">
                        <Label editorId={"attachments"}>
                          {t("Attachments")}
                        </Label>
                        <Upload
                          multiple={true}
                          defaultFiles={[]}
                          autoUpload={true}
                          withCredentials={true}
                          saveUrl={`${process.env.REACT_APP_HOST}/api/upload-files`}
                          removeUrl={`${process.env.REACT_APP_HOST}/api/upload-files`}
                          onStatusChange={(event) =>
                            addAttachmentsHandler(event)
                          }
                          onRemove={(event) => removeAttachmentHandler(event)}
                          restrictions={{
                            allowedExtensions: [
                              ".pdf",
                              ".docx",
                              ".xslx",
                              ".jpg",
                              ".png",
                              ".tiff",
                              ".zip",
                              ".xlsx",
                              ".xlsm",
                              ".pptm",
                              ".docm",
                            ],
                            maxFileSize: 31457280, //30MB
                          }}
                        />
                      </div>
                      <div className="form-item">
                        <Label editorId={"deadline"}>*{t("Deadline")}</Label>
                        <Field
                          name={"deadline"}
                          component={DeadlineDatePicker}
                        />
                      </div>
                      <div className="form-item">
                        <Label editorId={"priorityLevel"}>
                          *{t("PriorityLevel")}
                        </Label>
                        <Field
                          name={"priorityLevel"}
                          component={PriorityDropDownList}
                        />
                      </div>
                      <p>{t("ItIsRequiredToSelectMinimumOneAnswer")}</p>
                      {answersList.length > 1 &&
                        answersList.map((answer: any, index: number) => (
                          <div key={index}>
                            <div className="toggle-item">
                              <Label
                                editorId={`${answer.typeOfAnswerDictionaryId}`}
                              >
                                {t(answer.typeOfAnswerDictionaryName)}
                              </Label>
                              <Field
                                name={`${answer.typeOfAnswerDictionaryId}`}
                                component={AnswerSwitch}
                              />
                            </div>
                            {answer.typeOfAnswerDictionaryId === 5 &&
                              answersState.includes(5) && (
                                <Field
                                  name={"otherReason"}
                                  component={TextArea}
                                />
                              )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="button-container">
                    <Button type="button" onClick={() => navigate("/")}>
                      {t("Back")}
                    </Button>
                    <Button type="button" onClick={() => setStep(0)}>
                      {t("PrevStep")}
                    </Button>
                    <Button data-testid="submit" type={"submit"}>
                      {t("Submit")}
                    </Button>
                  </div>
                </>
              ))}
          </FormElement>
        )}
      />
    </div>
  );
};

export default CreateRequestForm;
