import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { CompanyDto, Roles } from "../../shared/sharedModel";
import { useQuery } from "react-query";
import { GetCompanies } from "../../shared/apiService";
import { useState } from "react";

export const UsersDropDownCompaniesCell = (props: GridCellProps) => {
  const [comapniesData, setCompaniesData] = useState<CompanyDto[]>();

  useQuery<any, Error>(["companies"], () => GetCompanies(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setCompaniesData(data.data);
    },
  });

  const tractebelUserRoles = [
    Roles.Admin,
    Roles.SubjectSpoc,
    Roles.TeSpoc,
    Roles.TeExpert,
  ];

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.id,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  return (
    <td>
      {dataItem.inEdit && comapniesData ? (
        <DropDownList
          style={{ width: "150px" }}
          onChange={handleChange}
          value={comapniesData.find((c) => c.id === dataValue)}
          data={comapniesData}
          textField="name"
          disabled={tractebelUserRoles.includes(dataItem.engieUserRole)}
        />
      ) : props.dataItem.company ? (
        props.dataItem.company.name
      ) : (
        ""
      )}
    </td>
  );
};
