import * as React from "react";
import {
  GridColumnMenuItemGroup,
  GridColumnMenuItemContent,
} from "@progress/kendo-react-grid";
import { columnList } from "./ColumnList";

interface CustomColumnMenuProps {
  columns: Array<any>;
  onColumnsSubmit: (event: any) => void;
  setShowDropdown: (event: any) => void;
}

export const CustomColumnMenu = (props: CustomColumnMenuProps) => {
  const [columns, setColumns] = React.useState<Array<any>>(props.columns);

  const onToggleColumn = (id: number) => {
    const newColumns = columns.map((column, idx) => {
      return idx === id ? { ...column, active: !column.active } : column;
    });
    setColumns(newColumns);
  };

  const onReset = (event: any) => {
    event.preventDefault();
    setColumns(columnList);
    props.onColumnsSubmit(columnList);
    props.setShowDropdown(false);
  };

  const onSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    props.onColumnsSubmit(columns);
    props.setShowDropdown(false);
  };

  const oneVisibleColumn = columns.filter((c) => c.show).length === 1;

  return (
    <GridColumnMenuItemGroup>
      Columns
      <GridColumnMenuItemContent show={true}>
        <div className={"k-column-list-wrapper"}>
          <form onSubmit={onSubmit} onReset={onReset}>
            <div className={"k-column-list"}>
              {columns.map((column, idx) => (
                <div key={idx} className={"k-column-list-item"}>
                  <span>
                    <input
                      id={`column-visiblity-show-${idx}`}
                      className="k-checkbox k-checkbox-md k-rounded-md"
                      type="checkbox"
                      readOnly={true}
                      disabled={column.active && oneVisibleColumn}
                      checked={column.active}
                      onClick={() => {
                        onToggleColumn(idx);
                      }}
                    />
                    <label
                      htmlFor={`column-visiblity-show-${idx}`}
                      className="k-checkbox-label"
                      style={{ userSelect: "none", marginLeft: "0.5rem" }}
                    >
                      {column.title}
                    </label>
                  </span>
                </div>
              ))}
            </div>
            <div className={"k-actions k-hstack k-justify-content-stretch"}>
              <button
                onClick={() => props.setShowDropdown(false)}
                className={
                  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                }
              >
                Cancel
              </button>
              <button
                type={"reset"}
                className={
                  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                }
              >
                Reset
              </button>
              <button
                className={
                  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                }
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </GridColumnMenuItemContent>
    </GridColumnMenuItemGroup>
  );
};
