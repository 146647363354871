import {
  Input,
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./CompanyForm.scss";

const CompanyForm = ({ toggleDialog, handleAddCompany }: any) => {
  const [companyName, setCompanyName] = useState("");
  const [trigram, setTrigram] = useState("");
  const [priority1, setPriority1] = useState<number | null>(null);
  const [priority2, setPriority2] = useState<number | null>(null);
  const [priority3, setPriority3] = useState<number | null>(null);

  const { t } = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newCompany = {
      name: companyName,
      trigram: trigram,
      hourlyRateForPriority1: priority1,
      hourlyRateForPriority2: priority2,
      hourlyRateForPriority3: priority3,
    };
    handleAddCompany(newCompany);
    toggleDialog();
  };

  return (
    <div className="companyForm--main">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="companyForm--field">
          <label>{t("CompanyName")}</label>
          <Input
            required
            onChange={(e) => {
              setCompanyName(e.value);
            }}
            validityStyles={false}
          />
        </div>
        <div className="companyForm--field">
          <label>{t("Trigram")}</label>
          <Input
            required
            onChange={(e) => {
              setTrigram(e.value);
            }}
            validityStyles={false}
          />
        </div>
        <div className="companyForm--field">
          <label style={{ marginBottom: "8px" }}>{t("HourlyRate")}</label>
          <label>{t("Priority")} 1 </label>
          <NumericTextBox
            required
            min={0}
            step={1}
            value={priority1}
            onChange={(e: NumericTextBoxChangeEvent) => {
              setPriority1(e.value);
            }}
            validityStyles={false}
          />
          <label>{t("Priority")} 2 </label>
          <NumericTextBox
            required
            min={0}
            step={1}
            value={priority2}
            onChange={(e: NumericTextBoxChangeEvent) => {
              setPriority2(e.value);
            }}
            validityStyles={false}
          />
          <label>{t("Priority")} 3 </label>
          <NumericTextBox
            required
            min={0}
            step={1}
            value={priority3}
            onChange={(e: NumericTextBoxChangeEvent) => {
              setPriority3(e.value);
            }}
            validityStyles={false}
          />
        </div>
        <div className="companyForm--field">
          <button
            type="submit"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          >
            {t("Create")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyForm;
