import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Roles, UserDto } from "../shared/sharedModel";
import { useQuery } from "react-query";
import { GetUserWithOkta, SetToken } from "../shared/apiService";
import { useOktaAuth } from "@okta/okta-react";
import { Loader } from "@progress/kendo-react-indicators";

import "./Authorization.scss";
import { UserContext } from "../UserContext/UserContext";

const Authorization = ({ roles }: { roles?: Array<Roles> }) => {
  let location = useLocation();

  const [view, setView] = useState(<div></div>);

  const { oktaAuth, authState } = useOktaAuth();

  const [user, setUser] = useContext(UserContext);

  SetToken(authState?.accessToken);

  const { data, isLoading } = useQuery<UserDto, Error>(
    ["userOkta"],
    () => GetUserWithOkta(),
    {
      onSuccess: (u) => setUser(u),
    }
  );

  useEffect(() => {
    let userHasRequiredRole = false;

    if (data) {
      if (roles) {
        userHasRequiredRole = roles.includes(data.engieUserRole);
      }
    }

    if (authState) {
      if (!authState.isAuthenticated) {
        oktaAuth.signInWithRedirect();
      } else if (roles && !userHasRequiredRole) {
        setView(<h1 style={{ marginTop: "80px" }}>Access denied</h1>);
      } else {
        setView(<Outlet />);
      }
    }
  }, [location, data, roles, authState]);

  return (
    <React.Fragment>
      {!isLoading ? (
        view
      ) : (
        <div className="authorization-container">
          <Loader type="pulsing" size="large" />
        </div>
      )}
    </React.Fragment>
  );
};

export default Authorization;
