import "@progress/kendo-theme-default/dist/all.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AdminPanel from "./AdminPanel/AdminPanel";
import Authorization from "./Authorization/Authorization";
import { Roles } from "./shared/sharedModel";
import Dashboard from "./Dashboard/Dashboards";
import NavMenu from "./NavMenu/NavMenu";
import TopBar from "./TopBar/TopBar";
import CreateRequestForm from "./RequestForm/CreateRequestForm";
import CompanyForm from "./CompanyForm/CompanyForm";
import { OktaAuth, OktaAuthOptions, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import RequestForm from "./RequestForm/RequestForm";
import "./shared/css/dropdowns.scss";
import { useState } from "react";
import { noUser, UserContext } from "./UserContext/UserContext";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();

let config: OktaAuthOptions = {
  issuer: process.env.REACT_APP_ISSUER,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
};

if (process.env.NODE_ENV === "test") {
  config = {
    ...config,
    transformAuthState: async (_authInstance: any, authState: any) => {
      authState.isAuthenticated = true;
      return authState;
    },
  };
}

const oktaAuth = new OktaAuth(config);

function App() {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  const user = useState(noUser);

  return (
    <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider value={user}>
          <div className="mainView">
            {user[0] !== noUser && (
              <div>
                <TopBar />
                <NavMenu />
              </div>
            )}
            <Routes>
              <Route element={<Authorization />}>
                <Route path="/" element={<Dashboard />} />
              </Route>
              <Route element={<Authorization roles={[Roles.Admin]} />}>
                <Route path="/admin" element={<AdminPanel />} />
              </Route>
              <Route
                element={
                  <Authorization
                    roles={[
                      Roles.Admin,
                      Roles.SubjectSpoc,
                      Roles.Requester,
                      Roles.TeExpert,
                      Roles.TeSpoc,
                      Roles.Validator,
                      Roles.Viewer,
                    ]}
                  />
                }
              >
                <Route path="/request/:id" element={<RequestForm />} />
              </Route>
              <Route
                element={
                  <Authorization
                    roles={[Roles.Requester, Roles.Validator, Roles.Admin]}
                  />
                }
              >
                <Route path="/request" element={<CreateRequestForm />} />
              </Route>
              <Route path="/company" element={<CompanyForm />} />
              <Route
                path={process.env.REACT_APP_LOGIN_CALLBACK}
                element={<LoginCallback />}
              />
            </Routes>
            <ToastContainer />
          </div>
        </UserContext.Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Security>
  );
}

export default App;
