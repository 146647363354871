import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useState } from "react";
import { useQuery } from "react-query";
import { GetSubjectSpocs } from "../../shared/apiService";
import { UserDto } from "../../shared/sharedModel";

export const SubjectsDropDownCell = (props: GridCellProps) => {
  const [subjectSpocsData, setSubjectSpocsData] = useState<UserDto[]>([]);

  useQuery<UserDto[], Error>("subjectSpocs", GetSubjectSpocs, {
    onSuccess: (data) => {
      setSubjectSpocsData(data);
    },
  });

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      const newData = props.dataItem;
      newData.subjectSpocApplicationUserId = e.value.id;
      props.onChange({
        dataIndex: 0,
        dataItem: newData,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.id,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";

  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const subjectSpoc = subjectSpocsData.find((usr) => usr?.id === dataValue);

  return (
    <td>
      {dataItem.inEdit ? (
        <DropDownList
          onChange={handleChange}
          value={subjectSpocsData.find((c) => c.id === dataValue)}
          data={subjectSpocsData}
          textField="fullName"
        />
      ) : subjectSpoc ? (
        subjectSpoc.fullName
      ) : (
        props.dataItem?.subjectSpocApplicationUser?.fullName || ""
      )}
    </td>
  );
};
