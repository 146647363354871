import { ColumnDto, RequestStatus } from "../shared/sharedModel";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import DatePickerFilterCell from "./DatePickerFilterCell";
import { StatusFilterCell } from "./StatusFilterCell";
import { priorityLevels } from "../shared/priorityLevels";
import { BooleanFilterCell } from "./BooleanFilterCell";

const statuses = [
  { text: "Submitted", value: 0 },
  { text: "Reviewing", value: 1 },
  { text: "Estimated", value: 2 },
  { text: "Accepted", value: 3 },
  { text: "InProgress", value: 4 },
  { text: "AwaitingFeedback", value: 5 },
  { text: "Finished", value: 6 },
  { text: "Closed", value: 7 },
  { text: "Revision", value: 8 },
  { text: "RejectedByTractebel", value: 9 },
  { text: "RejectedByClient", value: 10 },
];

const StatusCell = (props: any) => {
  return (
    <td>
      <div style={{ marginTop: "2px" }}>
        {
          RequestStatus[
            props.dataItem.setStatusDto
              ? props.dataItem.setStatusDto.currentStatus
              : 0
          ]
        }
      </div>
    </td>
  );
};

const PriorityCell = (props: any) => {
  const element = priorityLevels.find(
    (item) => item.id === props.dataItem.priorityLevel
  );
  return (
    <td>
      <div style={{ marginTop: "2px" }}>{element?.value}</div>
    </td>
  );
};

const RequestedDeadlineFilterCell: any = (props: GridFilterCellProps) => (
  <DatePickerFilterCell {...props} />
);

const NewStatusFilterCell: any = (props: GridFilterCellProps) => (
  <StatusFilterCell {...props} data={statuses} />
);

const ClosureFilerCell: any = (props: GridFilterCellProps) => (
  <DatePickerFilterCell {...props} />
);

const PropsedDeadlineFilerCell: any = (props: GridFilterCellProps) => (
  <DatePickerFilterCell {...props} />
);

const IsSeismicFilterCell: any = (props: GridFilterCellProps) => (
  <BooleanFilterCell {...props} />
);

const ReferenceCheckboxStatus: any = (props: GridFilterCellProps) => (
  <BooleanFilterCell {...props} />
);

export const columnList: ColumnDto[] = [
  {
    title: "Id",
    field: "requestId",
    width: 150,
    active: true,
  },
  {
    title: "Title",
    field: "title",
    active: true,
  },
  {
    title: "Subject",
    field: "subject.name",
    active: true,
  },
  {
    title: "Requester",
    field: "requesterApplicationUser.fullName",
    active: true,
  },
  {
    title: "Request deadline",
    field: "deadline",
    filter: "date",
    filterCell: RequestedDeadlineFilterCell,
    format: "{0: dd-MM-yyyy}",
    active: true,
  },
  {
    title: "Status",
    field: "status",
    width: 200,
    cell: StatusCell,
    filterCell: NewStatusFilterCell,
    active: true,
  },
  {
    title: "Closure",
    field: "closureDate",
    filter: "date",
    filterCell: ClosureFilerCell,
    format: "{0: dd-MM-yyyy}",
    active: true,
  },
  {
    title: "Department",
    field: "department.name",
    active: false,
  },
  {
    title: "Request in the name of",
    field: "requestInTheNameOf",
    active: false,
  },
  {
    title: "Date Created",
    field: "createAt",
    filter: "date",
    filterable: false,
    format: "{0:g}",
    active: false,
  },
  {
    title: "Proposed deadline",
    field: "proposedDeadline",
    filter: "date",
    filterCell: PropsedDeadlineFilerCell,
    format: "{0: dd-MM-yyyy}",
    active: false,
  },
  {
    title: "Estimation (in hours)",
    field: "estimationHours",
    filter: "numeric",
    active: false,
  },
  {
    title: "Type of reference",
    field: "reference.name",
    active: false,
  },
  {
    title: "Reference content",
    field: "referenceNumber",
    active: false,
  },

  {
    title: "Description",
    field: "description",
    active: false,
  },
  {
    title: "Validator",
    field: "validatorApplicationUser.fullName",
    active: false,
  },
  {
    title: "Tractebel SPOC",
    field: "teSpocApplicationUser.fullName",
    active: false,
  },
  {
    title: "Tractebel Expert",
    field: "teExpertApplicationUser.fullName",
    active: false,
  },
  {
    title: "Request reference",
    field: "wdNumber",
    active: false,
  },
  {
    title: "Project number",
    field: "projectNumber",
    active: false,
  },
  {
    title: "Imputation number",
    field: "imputationNumber",
    active: false,
  },
  {
    title: "Is seismic",
    field: "isSeismic",
    filterCell: IsSeismicFilterCell,
    active: false,
  },
  {
    title: "Reference checkbox",
    field: "reference.referenceCheckboxLabel",
    active: false,
  },
  {
    title: "Reference checkbox status",
    field: "referenceCheckbox",
    filterCell: ReferenceCheckboxStatus,
    active: false,
  },
  {
    title: "Hourly Rate",
    field: "hourlyRate",
    filter: "numeric",
    active: false,
  },
  {
    title: "Estimation cost",
    field: "estimationCost",
    filter: "numeric",
    active: false,
  },
  {
    title: "Estimation comment",
    field: "estimationComment",
    active: false,
  },
  {
    title: "Priority level",
    field: "priorityLevel",
    cell: PriorityCell,
    active: false,
  },
  {
    title: "Budget owner",
    field: "budgetOwner",    
    active: false,
  },
];
