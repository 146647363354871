import React, { useState } from 'react';
import { Button, Comment, Form, Header } from "semantic-ui-react";
import { CommentDto } from '../shared/sharedModel';
import "./ChatComponent.scss";
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { Button as KendoButton } from "@progress/kendo-react-buttons";


const ChatComponent = ({
    comments, 
    onPostComment, 
    onRemoveComment
    }: {
        comments: CommentDto[], 
        onPostComment: (data:string) => void, 
        onRemoveComment: (commentId: string) => void}) => {

    const [commentBody, setCommentBody] = useState('');

    const handleCommentTextAreaChange = (e: TextAreaChangeEvent) => {
        setCommentBody(e.value)
    }

    const submitComment = () => {
        onPostComment(commentBody);
        setCommentBody("")
    }

    const removeComment = (c:CommentDto) => {
        onRemoveComment(c.id!);
    }

    return (
        <div className="comment-section-wrapper">
            <div className="comment-feed">
                <Comment.Group>
                    <Header as="h3" dividing>
                        Comments
                    </Header>
                    {comments.map((comment: CommentDto) => {
                        return (
                            <Comment>
                                <Comment.Author as="a">
                                    {comment.authorName}
                                </Comment.Author>
                                <Comment.Metadata>
                                    <div>{comment.timestamp !== undefined ? `${new Date(comment.timestamp).toLocaleDateString()}  ${new Date(comment.timestamp).toLocaleTimeString()}` : ''}</div>
                                </Comment.Metadata>
                                <Comment.Content>
                                    {comment.content}
                                </Comment.Content>
                                {comment.isAuthor ?
                                <Comment.Actions>
                                    <Comment.Action onClick={() => removeComment(comment)}>Remove</Comment.Action>
                                </Comment.Actions>
                                : null
                                }
                            </Comment>
                        );
                    })}
                </Comment.Group>
                <TextArea 
                    value={commentBody}
                    onChange={(e) => handleCommentTextAreaChange(e)}
                    >

                </TextArea>
                <KendoButton type="button" onClick={submitComment}>
                    Submit
                </KendoButton>
            </div>
        </div>
    )
}


export default ChatComponent;