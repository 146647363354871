export enum Roles {
  Unassigned = 0,
  Admin = 1,
  Viewer = 2,
  SubjectSpoc = 3,
  Validator = 4,
  TeSpoc = 5,
  TeExpert = 6,
  Requester = 7,
}

export enum RequestStatus {
  Submitted = 0,
  Reviewing = 1,
  Estimated = 2,
  Accepted = 3,
  InProgress = 4,
  AwaitingFeedback = 5,
  Finished = 6,
  Closed = 7,
  Revision = 8,
  RejectedByTractebel = 9,
  RejectedByClient = 10,
}

export enum RequestTypeAnswer {
  EmailAnswer = 0,
  OfficialNote = 1,
  Walkdown = 2,
  MeetingOrCall = 3,
  PlansOrShedule = 4,
  Other = 5,
}

export enum RequestPriorityLevel {
  "Level1" = 1,
  "Level2" = 2,
  "Level3" = 3,
}

export interface UserDto {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  tractebelEmployee: boolean;
  company?: CompanyDto;
  companyId?: number;
  engieUserRole: Roles;
  inEdit?: boolean;
  fullName?: string;
  isActive?: boolean;
  isOnVacation?: boolean;
  vacationFrom?: any;
  vacationTo?: Date;
}

export interface SubjectDto {
  id: number;
  name: string;
  subjectSpocApplicationUserId: string;
  trigram?: string;
  inEdit?: boolean;
  childSubjectId: number;
  childSubject?: SubjectDto[];
  subjectEntityId: number;
  items?: SubjectDto[];
  active: boolean;
  companyId: number;
  subjectOrder: number;
  company: CompanyDto;
  descriptions: any[];
}

export interface SetStatusDto {
  requestId: string;
  currentStatus: number;
  futureStatus: number;
}

export interface RequestDto {
  id?: number;
  requestId: string;
  title: string;
  description: string;
  requesterApplicationUserId: string;
  requesterApplicationUser?: UserDto;
  validatorApplicationUserId: string;
  validatorApplicationUser?: UserDto;
  teSpocApplicationUserId?: string;
  teSpocApplicationUser?: UserDto;
  teExpertApplicationUserId?: string;
  teExpertApplicationUser?: UserDto;
  companyId?: number;
  company?: CompanyDto | null;
  subjectId: number;
  subject?: SubjectDto;
  deadline: string; //date
  priorityLevel?: RequestPriorityLevel;
  wdNumber?: string;
  projectNumber?: string;
  imputationNumber?: string;
  selectedTypesOfAnswer: any; //@todo
  otherReason?: string;
  attachments?: any; //@todo
  setStatusDto?: SetStatusDto;
  rejectionReason?: string;
  estimationHours?: number;
  proposedDeadline?: string;
  estimationComment?: string;
  estimationCost?: number;
  hourlyRate?: number;
  requestInTheNameOf?: string;
  referenceCheckbox?: boolean;
  referenceId?: number;
  referenceNumber?: number;
  reference?: ReferenceDto;
  isSeismic?: boolean;
  department?: DepartmentDto;
  departmentId?: number;
  budgetOwner?: string;
}

interface ReferenceDto {
  name: string;
  id: number;
  active: boolean;
  referenceCheckboxLabel?: string;
  companyId: number;
  company?: CompanyDto;
}

export interface CompanyDto {
  id: number;
  name: string;
  trigram: string;
  hourlyRateForPriority1: number;
  hourlyRateForPriority2: number;
  hourlyRateForPriority3: number;
  inEdit?: boolean;
  companySubjects: CompanySubjectDto[];
  isWDApplicable: boolean;
  lastRequestNumber?: boolean;
}

export interface StatusDto {
  requestStatusValue: number;
  description: string;
}

export interface CommentDto {
  id?: string;
  authorName?: string;
  content: string;
  timestamp?: Date;
  isAuthor?: boolean;
  requestEntityId: number;
}

export interface CompanySubjectDto {
  id: number;
  companyId: number;
  company?: CompanyDto;
  subjectId: number;
  subject?: SubjectDto;
  subjectSpocApplicationUserId: string;
  subjectSpocApplicationUser?: UserDto;
}

export interface DepartmentDto {
  id?: number;
  companyId: number;
  company?: CompanyDto;
  name: string;
  active: boolean;
  inEdit?: boolean;
}

export interface ColumnDto extends ShortColumnDto {
  id?: number;
  width?: string | number;
  cell?: any;
  filter?: any;
  filterCell?: any;
  format?: string;
  filterable?: boolean;
  sortable?: boolean;
}

export interface ShortColumnDto {
  title?: string;
  field: string;
  active: boolean;
}
