import { CompanyDto, DepartmentDto } from "../../shared/sharedModel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, UseQueryResult } from "react-query";
import { AddDepartment, UpdateDepartment } from "../../shared/apiService";
import { toast } from "react-toastify";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import MyCommandCell from "../MyCommandCell";
import { Dialog } from "@progress/kendo-react-dialogs";
import ActiveDropDownCell from "../ActiveDropDownCell";
import { DepartmentForm } from "./DepartmentForm";

const Departments = ({
  selectedCompany,
  departmentsQuery,
  departments,
}: {
  selectedCompany: CompanyDto;
  departmentsQuery: UseQueryResult<any, unknown>;
  departments: DepartmentDto[];
}) => {
  const [departmentsData, setDepartmentsData] = useState<any>(departments);

  useEffect(() => {
    setDepartmentsData(departments);
  }, [departments]);

  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const editField: string = "inEdit";

  const updateDepartment = useMutation(UpdateDepartment, {
    onSuccess: () => {
      toast.success("Department saved!");
      departmentsQuery.refetch();
    },
    onError: (error: any) => {
      toast.error(`Department couldn't be saved: ${error.response.data}`);
    },
  });

  const addDepartment = useMutation(AddDepartment, {
    onSuccess: () => {
      toast.success("Department added!");
      departmentsQuery.refetch();
    },
    onError: (error: any) => {
      toast.error(`Department couldn't be added: ${error.response.data}`);
    },
  });

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleAddDepartment = (newDepartment: DepartmentDto) => {
    addDepartment.mutate(newDepartment);
  };

  const enterEdit = (dataItem: any) => {
    setDepartmentsData(
      departmentsData.map((sub: DepartmentDto) =>
        sub.name === dataItem.name ? { ...sub, inEdit: true } : sub
      )
    );
  };

  const discard = () => {
    const data = [...departmentsData];
    data.splice(0, 1);
    setDepartmentsData(data);
  };

  const update = (dataItem: DepartmentDto) => {
    dataItem.inEdit = false;
    dataItem.companyId = selectedCompany.id;
    updateDepartment.mutate(dataItem);
  };

  const cancel = (dataItem: DepartmentDto) => {
    const originalItem = departments.find(
      (dep: DepartmentDto) => dep.id === dataItem.id
    );
    if (originalItem) {
      originalItem.inEdit = false;
    }
    const data = departmentsData.map((dep: DepartmentDto) =>
      dep.id === originalItem?.id ? originalItem : dep
    );
    setDepartmentsData(data);
  };

  const CommandCell = (props: GridCellProps) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const itemChange = (event: GridItemChangeEvent) => {
    const data = departmentsData.map((sub: any) =>
      sub.name === event.dataItem.name
        ? { ...sub, [event.field || ""]: event.value }
        : sub
    );
    setDepartmentsData(data);
  };

  return (
    <div className="adminPanel--container">
      {visible && (
        <Dialog title={t("AddNewDepartment")} onClose={toggleDialog}>
          <DepartmentForm
            toggleDialog={toggleDialog}
            handleAddDepartment={handleAddDepartment}
            selectedCompany={selectedCompany}
          />
        </Dialog>
      )}
      <Grid
        className="users--grid"
        data={departmentsData}
        editField={editField}
        onItemChange={itemChange}
      >
        <GridToolbar>
          <div>
            <button
              title={t("AddDepartment")}
              className="admin-add--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={toggleDialog}
            >
              {t("AddDepartment")}
            </button>
          </div>
        </GridToolbar>
        <GridColumn field="name" title={t("Name")} />
        <GridColumn
          field="active"
          title={t("Active")}
          cell={ActiveDropDownCell}
        />
        <GridColumn cell={CommandCell} width="100px" />
      </Grid>
    </div>
  );
};

export default Departments;
