import { useState } from "react";
import { State } from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterCellProps,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";

import "../AdminPanel.scss";
import "../../shared/css/grids.scss";
import { Dialog } from "@progress/kendo-react-dialogs";
import UserForm from "../../UserForm/UserForm";
import { UserDto } from "../../shared/sharedModel";
import MyCommandCell from "../MyCommandCell";
import { UsersDropDownRolesCell } from "./UsersDropDownRolesCell";
import { useMutation, useQuery } from "react-query";
import { GetUsers, PostUser, UpdateUser } from "../../shared/apiService";
import { Loader } from "@progress/kendo-react-indicators";
import { toast } from "react-toastify";
import { RolesFilterCell } from "./RolesFilterCell";
import { useTranslation } from "react-i18next";
import { UsersDropDownCompaniesCell } from "./UsersDropDownCompaniesCell";
import { UsersActiveDropDownCell } from "./UsersActiveDropDownCell";

const initialDataState: State = {
  sort: [],
  take: 25,
  skip: 0,
};

const Users = () => {
  const [visible, setVisible] = useState(false);
  const [usersDataState, setUsersDataState] = useState<State>(initialDataState);
  const [totalState, setTotalState] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [usersData, setUsersData] = useState<UserDto[]>([]);

  const { t } = useTranslation();

  const users = useQuery<any, Error>(
    ["users", usersDataState],
    () => GetUsers(usersDataState),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setUsersData(data.data);
        setTotalState(data.total);
        setIsLoading(false);
      },
    }
  );

  const postUser = useMutation(PostUser, {
    onSuccess: () => {
      users.refetch();
      toast.success("User saved!");
    },
    onError: () => {
      users.refetch();
      toast.error(`User couldn't be saved`);
    },
  });

  const updateUser = useMutation(UpdateUser, {
    onSuccess: () => {
      users.refetch();
      toast.success("User updated!");
    },
    onError: () => {
      users.refetch();
      toast.error(`User couldn't be updated`);
    },
  });

  const editField: string = "inEdit";

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleAddUser = (newUser: UserDto) => {
    postUser.mutate(newUser);
  };

  const enterEdit = (dataItem: any) => {
    setUsersData(
      usersData.map((usr) =>
        usr.id === dataItem.id ? { ...usr, inEdit: true } : usr
      )
    );
  };

  const discard = () => {
    const data = [...usersData];
    data.splice(0, 1);
    setUsersData(data);
  };

  const update = (dataItem: any) => {
    dataItem.inEdit = false;
    updateUser.mutate(dataItem);
  };

  const cancel = (dataItem: any) => {
    const originalUser = users.data.data.find(
      (usr: UserDto) => usr.id === dataItem.id
    );
    if (originalUser) {
      originalUser.inEdit = false;
    }
    const data = usersData.map((usr) => {
      if (usr.id) {
        if (usr.id === originalUser?.id) {
          return originalUser;
        } else {
          return usr;
        }
      } else {
        if (usr.firstName === originalUser?.firstName) {
          return originalUser;
        } else {
          return usr;
        }
      }
    });
    if (data) setUsersData(data);
  };

  const CommandCell = (props: GridCellProps) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const employeeCell = (props: any) => {
    return (
      <td>
        <div style={{ marginTop: "2px" }}>
          {props.dataItem.tractebelEmployee ? "Yes" : "No"}
        </div>
      </td>
    );
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const data = usersData.map((usr) =>
      usr.id === event.dataItem.id
        ? { ...usr, [event.field || ""]: event.value }
        : usr
    );
    setUsersData(data);
  };

  const roles = [
    { text: "Unassigned", value: 0 },
    { text: "Admin", value: 1 },
    { text: "Viewer", value: 2 },
    { text: "SubjectSpoc", value: 3 },
    { text: "Validator", value: 4 },
    { text: "TeSpoc", value: 5 },
    { text: "TeExpert", value: 6 },
    { text: "Requester", value: 7 },
  ];

  const CategoryFilterCell: any = (props: GridFilterCellProps) => (
    <RolesFilterCell {...props} data={roles} />
  );

  return (
    <div className="adminPanel--container">
      {isLoading ? (
        <div className="adminPanel--loading">
          <Loader type="pulsing" size="large" />
        </div>
      ) : (
        <div>
          {visible && (
            <Dialog title={t("AddNewUser")} onClose={toggleDialog}>
              <UserForm
                toggleDialog={toggleDialog}
                handleAddUser={handleAddUser}
              />
            </Dialog>
          )}
          <Grid
            className="users--grid"
            data={usersData}
            {...usersDataState}
            resizable={true}
            sortable={true}
            filterable={true}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setUsersDataState(e.dataState);
            }}
            editField={editField}
            onItemChange={itemChange}
            pageable={true}
            total={totalState}
            onPageChange={(e) => {
              if (usersDataState.skip !== e.page.skip) {
                setIsLoading(true);
                let newUsersDataState = usersDataState;
                newUsersDataState.skip = e.page.skip;
                newUsersDataState.take = e.page.take;
                setUsersDataState(newUsersDataState);
              }
            }}
          >
            <GridToolbar>
              <div>
                <button
                  title={t("AddUser")}
                  className="admin-add--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={toggleDialog}
                >
                  {t("AddUser")}
                </button>
              </div>
            </GridToolbar>
            <GridColumn field="email" title="E-mail" />
            <GridColumn field="firstName" title={t("FirstName")} />
            <GridColumn field="lastName" title={t("LastName")} />
            <GridColumn
              field="tractebelEmployee"
              cell={employeeCell}
              title={t("TractebelEmployee")}
              filter="boolean"
            />
            <GridColumn
              field="companyId"
              title={t("Company")}
              cell={UsersDropDownCompaniesCell}
            />
            <GridColumn
              field="engieUserRole"
              title={t("Role")}
              cell={UsersDropDownRolesCell}
              filterCell={CategoryFilterCell}
            />
            <GridColumn
              field="isActive"
              title={t("Active")}
              filter="boolean"
              cell={UsersActiveDropDownCell}
              //editable={false}
            />
            <GridColumn
              width="100px"
              cell={CommandCell}
              filterable={false}
              sortable={false}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Users;
