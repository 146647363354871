import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridCellProps } from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";

export const ActiveDropDownCell = (props: GridCellProps) => {
  const activeStatuses = [true, false];

  const { t } = useTranslation();

  const handleChange = (e: DropDownListChangeEvent) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  const { dataItem } = props;
  const field = props.field || "";
  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  return (
    <td>
      {dataItem.inEdit ? (
        <DropDownList
          onChange={handleChange}
          data={activeStatuses}
          value={activeStatuses.find((s) => s === dataValue)!.toString()}
        />
      ) : (
        t(dataValue)
      )}
    </td>
  );
};

export default ActiveDropDownCell;
