import { createContext } from "react";
import { Roles, UserDto } from "../shared/sharedModel";

export const noUser: UserDto = {
  email: "",
  firstName: "",
  lastName: "",
  tractebelEmployee: false,
  engieUserRole: Roles.Unassigned,
};

export const UserContext = createContext<[UserDto, (user: UserDto) => void]>([
  {} as UserDto,
  () => {},
]);
