import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterCellProps,
  GridFilterChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import {
  ColumnDto,
  RequestDto,
  RequestStatus,
  Roles,
  ShortColumnDto,
} from "../shared/sharedModel";

import "./Dashboard.scss";
import "../shared/css/grids.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { State } from "@progress/kendo-data-query";
import { useQuery } from "react-query";
import {
  GetCurrentView,
  GetExportToExcel,
  GetRequests,
  UpdateCurrentView,
} from "../shared/apiService";
import { useNavigate } from "react-router";
import { Loader } from "@progress/kendo-react-indicators";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { UserContext } from "../UserContext/UserContext";
import { columnList } from "./ColumnList";
import { CustomColumnMenu } from "./CustomColumnMenu";
import { Dialog } from "@progress/kendo-react-dialogs";

const initialDataState: State = {
  sort: [],
  take: 20,
  skip: 0,
};

const Dashboard = () => {
  const [requestData, setRequestData] = useState<RequestDto[]>([]);
  const [filter, setFilter] = useState<any>();
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [totalState, setTotalState] = useState<number>();
  const [isLoading, setIsLoading] = useState(process.env.NODE_ENV === "test");

  const [stateColumns, setStateColumns] =
    React.useState<ColumnDto[]>(columnList);

  const { t } = useTranslation();

  //const user = useQuery<UserDto, Error>(["userOkta"], () => GetUserWithOkta());
  const [user] = useContext(UserContext);

  const responseData = useQuery<any, Error>(
    ["requests", dataState],
    () => GetRequests({ ...dataState, filter }),
    {
      onSuccess: (response) => {
        deadlineStringToDate(response.data);
        closureStringToDate(response.data);
        setRequestData(response);
        setTotalState(response.total);
        setIsLoading(false);
      },
      keepPreviousData: true,
    }
  );

  const combineArrays = (
    array1: ColumnDto[],
    array2: ShortColumnDto[]
  ): ColumnDto[] => {
    return array1.map((item1: ColumnDto) => {
      const item2 = array2.find(
        (item2: ShortColumnDto) => item2.field === item1.field
      );
      return item2 ? { ...item1, ...item2 } : item1;
    });
  };

  useQuery("get current view", GetCurrentView, {
    onSuccess: (response) => {
      if (response[response.length - 1]?.json) {
        const parsedColumnState = JSON.parse(
          response[response.length - 1]?.json
        );
        setStateColumns((currentColumnState) =>
          combineArrays(currentColumnState, parsedColumnState)
        );
      }
    },
    enabled: !!user.id,
  });

  useEffect(() => {
    setTimeout(() => responseData.refetch(), 1000);
  }, [filter]);

  let navigate = useNavigate();

  const getDate = () => {
    let date = new Date();
    let dd = date.getDate();
    let mm = date.getMonth();
    let yyyy = date.getFullYear();
    let hh = date.getHours();
    let min = date.getMinutes();

    return hh + "_" + min + "_" + dd + "_" + mm + "_" + yyyy + "_";
  };

  const deadlineStringToDate = (requests: Array<any>) => {
    for (const request of requests) {
      request.deadline = new Date(request.deadline);
      if (request.proposedDeadline) {
        request.proposedDeadline = new Date(request.proposedDeadline);
      }
      if (request.closureDate) {
        request.closureDate = new Date(request.closureDate);
      }
      if (request.createAt) {
        request.createAt = new Date(request.createAt);
      }
    }
  };

  const closureStringToDate = (requests: any[]) => {
    for (const request of requests) {
      if (request.closureDate)
        request.closureDate = new Date(request.closureDate);
    }
  };

  const buttonCell = (props: any) => {
    return (
      <td style={props.style} className={props.className} role={"gridcell"}>
        <div style={{ marginTop: "2px", textAlign: "center" }}>
          <button
            className="dashboard-newRequest--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            onClick={() => {
              navigate(`request/${props.dataItem.id}`);
            }}
          >
            {t("Details")}
          </button>
        </div>
      </td>
    );
  };

  const onColumnsSubmit = (columnsState: Array<any>) => {
    const parsedColumnState = columnsState.map((element) => {
      return {
        field: element.field,
        active: element.active,
      };
    });
    UpdateCurrentView(parsedColumnState).then(() =>
      setStateColumns((currentColumnState) =>
        combineArrays(currentColumnState, parsedColumnState)
      )
    );
  };

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="dashboard-main">
      {showDropdown && (
        <Dialog>
          <CustomColumnMenu
            columns={stateColumns}
            onColumnsSubmit={onColumnsSubmit}
            setShowDropdown={setShowDropdown}
          />
        </Dialog>
      )}
      {isLoading ? (
        <div className="dashboard--loading">
          <Loader type="pulsing" size="large" />
        </div>
      ) : (
        <div>
          <h1 data-testid="title">{t("Dashboard")}</h1>
          <Grid
            data={requestData}
            {...dataState}
            resizable={true}
            sortable={true}
            filter={filter}
            onFilterChange={(e: GridFilterChangeEvent) => {
              setFilter(e.filter);
            }}
            filterable={true}
            pageable={true}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
            total={totalState}
            onPageChange={(e) => {
              if (dataState.skip !== e.page.skip) {
                setIsLoading(true);
                let newDataState = dataState;
                newDataState.skip = e.page.skip;
                newDataState.take = e.page.take;
                setDataState(newDataState);
              }
            }}
          >
            <GridToolbar>
              <div>
                {[Roles.Requester, Roles.Validator, Roles.Admin].includes(
                  user.engieUserRole
                ) && (
                  <button
                    data-testid="newRequest"
                    title={t("NewRequest")}
                    className="dashboard-newRequest--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={() => {
                      navigate("/request");
                    }}
                  >
                    {t("NewRequest")}
                  </button>
                )}
                <button
                  title={t("ExportToExcel")}
                  className="dashboard-newRequest--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    const parsedColumnState = stateColumns
                      .filter((x) => x.active)
                      .map((element: ColumnDto) => {
                        return {
                          title: element.title,
                          field: element.field,
                          active: element.active,
                        };
                      });

                    GetExportToExcel(parsedColumnState, {
                      ...dataState,
                      filter,
                    }).then((result) => {
                      let date = getDate();
                      saveAs(result, `dashboard_export_${date}.xlsx`);
                    });
                  }}
                >
                  {t("ExportToExcel")}
                </button>
                <button
                  title={t("SelectColumns")}
                  className="dashboard-newRequest--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setShowDropdown(true)}
                >
                  {t("SelectColumns")}
                </button>
              </div>
            </GridToolbar>
            {stateColumns
              .filter((col) => col.active)
              .map(({ id, active, ...col }) => (
                <GridColumn width="200px" {...col} />
              ))}
            <GridColumn
              cell={buttonCell}
              width="100px"
              filterable={false}
              sortable={false}
              locked={true}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
