import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import {
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
} from "@progress/kendo-react-treeview";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  GetChildrenOfSubject,
  GetCompanies,
  GetSubjectsOfCompany,
} from "../../shared/apiService";
import { CompanyDto, SubjectDto } from "../../shared/sharedModel";
import Subjects from "./Subjects";

import "./SubjectsTree.scss";

export const SubjectsTree = () => {
  const [subjects, setSubjects] = useState([]);

  const [subjectsToShow, setSubjectsToShow] = useState([]);

  const [select, setSelect] = useState<number>();

  const [companies, setCompanies] = useState<CompanyDto[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<CompanyDto>();

  useQuery<any, Error>("companies", () => GetCompanies(), {
    onSuccess: (data) => setCompanies(data.data),
  });

  const subjectsQuery = useQuery(
    ["subjects", selectedCompany?.id],
    () => GetSubjectsOfCompany(selectedCompany?.id!),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedCompany,
      onSuccess: (data) => {
        const subItemIds: number[] = [];
        if (data.length !== 0) {
          data.forEach((sub: SubjectDto) => {
            if (sub.childSubject) {
              sub.items = sub.childSubject;
              sub.items.forEach((child) => {
                if (child.childSubject) {
                  child.items = child.childSubject;
                  child.items.forEach((grandChild) => {
                    if (grandChild.childSubject) {
                      grandChild.items = grandChild.childSubject;
                    }
                  });
                }
              });
            }
            if (sub.subjectEntityId) {
              subItemIds.push(sub.id);
            }
          });
          const filteredData = data.filter(
            (s: SubjectDto) => !subItemIds.includes(s.id)
          );
          filteredData.sort((sub1: SubjectDto, sub2: SubjectDto) =>
            sub2.name !== "Other" ? sub1.subjectOrder - sub2.subjectOrder : -1
          );
          const result: any = [{ name: "All", items: filteredData }];
          if (result !== subjects) {
            setSubjects(result);
          }
          setSubjectsToShow(filteredData);
          setSelect(undefined);
        } else {
          setSubjects(data);
          setSubjectsToShow(data);
        }
      },
    }
  );

  const subjectsToShowQuery = useQuery(
    ["childrenOfSubject", select, selectedCompany?.id],
    () => GetChildrenOfSubject(select, selectedCompany?.id),
    {
      enabled: !!select,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setSubjectsToShow(data);
      },
    }
  );

  function onItemClick(event: TreeViewItemClickEvent) {
    if (event.item.id && event.item.childSubject.length !== 0) {
      setSelect(event.item.id);
    } else if (!event.item.id) {
      setSelect(undefined);
      subjectsQuery.refetch();
    } else {
      return;
    }
  }
  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    event.item.opened = !event.item.opened;
  };

  return (
    <div>
      <div>
        {!selectedCompany && <h3>Please select a company:</h3>}
        <DropDownList
          data={companies}
          onChange={(e) => setSelectedCompany(e.value)}
          textField="name"
          dataItemKey="id"
          value={selectedCompany}
          style={{ width: "400px" }}
        />
        {subjectsQuery.isLoading ? (
          <div className="subjectTree--loading">
            <Loader type="pulsing" size="large" />
          </div>
        ) : (
          <div>
            {selectedCompany && (
              <div>
                <TreeView
                  data={subjects}
                  textField="name"
                  expandIcons={true}
                  expandField="opened"
                  onExpandChange={onExpandChange}
                  onItemClick={onItemClick}
                />
                {subjectsToShowQuery.isFetching ? (
                  <div className="subjectTree-subjectsGrid--loading">
                    <Loader type="pulsing" size="large" />
                  </div>
                ) : (
                  <Subjects
                    subjectsToShow={subjectsToShow}
                    subjectsQuery={subjectsQuery}
                    selectedCompany={selectedCompany}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
