import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  GetSubjectsOfCompany,
  UpdateSubjectOfTheRequest,
} from "../shared/apiService";
import { RequestDto, SubjectDto } from "../shared/sharedModel";
import { useTranslation } from "react-i18next";

const SubjectDialog = ({
  requestBody,
  setIsSubjectDialogVisible,
  getRequestById,
}: {
  requestBody: RequestDto | undefined;
  setIsSubjectDialogVisible: any;
  getRequestById: any;
}) => {
  const [subjectsList, setSubjectsList] = useState<SubjectDto[]>();
  const [selectedSubject, setSelectedSubject] = useState<SubjectDto>();

  const { t } = useTranslation();

  useQuery(
    "get all subjects of company",
    () => GetSubjectsOfCompany(requestBody?.companyId!),
    {
      onSuccess: (data) => setSubjectsList(data),
    }
  );

  const submit = () => {
    UpdateSubjectOfTheRequest(
      requestBody?.id!,
      selectedSubject?.id!,
      requestBody?.companyId!
    ).then(() => {
      getRequestById.refetch();
      setIsSubjectDialogVisible(false);
    });
  };

  return (
    <div>
      <span>{t("SelectNewSubject")}</span>
      <ComboBox
        textField="name"
        data={subjectsList}
        onChange={(event) => setSelectedSubject(event.target.value)}
      />
      <div className="dialog-buttons">
        <Button type="button" onClick={submit}>
          Confirm
        </Button>
        <Button type="button" onClick={() => setIsSubjectDialogVisible(false)}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default SubjectDialog;
