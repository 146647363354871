import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { UserDto } from "../shared/sharedModel";
import { t } from "i18next";

export const checkForVacation = (event: ComboBoxChangeEvent) => {
  if (event.value.isOnVacation) {
    const vacationFrom = new Date(event.value.vacationFrom);
    const vacationTo = new Date(event.value.vacationTo);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (vacationFrom <= today) {
      toast.info(
        `${t("ThisUserIsOnVacationAndWIllBeBackOn")} ${vacationTo.getDate()}/${
          vacationTo.getMonth() + 1
        }/${vacationTo.getFullYear()}`
      );
    }
  }
};

export const displayVacationInfo = (user: UserDto) => {
  const vacationTo = new Date(user?.vacationTo!);
  return `${t("ThisUserIsOnVacationAndWIllBeBackOn")} ${vacationTo.getDate()}/${
    vacationTo.getMonth() + 1
  }/${vacationTo.getFullYear()}`;
};
