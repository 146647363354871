import { DatePicker } from "@progress/kendo-react-dateinputs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./VacationForm.scss";

export const VacationForm = ({
  onSubmitDialog,
}: {
  onSubmitDialog: (from: Date, to: Date) => void;
}) => {
  const [fromDate, setFromDate] = useState<Date | null>();
  const [toDate, setToDate] = useState<Date | null>();

  const validateForm = () => {
    if (fromDate && toDate) {
      onSubmitDialog(fromDate, toDate);
    } else {
      toast.error("Fill all fields");
    }
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="vacation-form-container">
        <label>{t("From")}</label>
        <DatePicker
          className="vacation-form-datepicker"
          onChange={(e) => setFromDate(e.value)}
          format="dd/MM/yyyy"
          min={new Date()}
        />
        <label>{t("To")}</label>
        <DatePicker
          className="vacation-form-datepicker"
          onChange={(e) => setToDate(e.value)}
          format="dd/MM/yyyy"
          min={fromDate ? fromDate : new Date()}
        />
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
          onClick={validateForm}
        >
          {t("Submit")}
        </button>
      </div>
    </React.Fragment>
  );
};
