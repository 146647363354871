import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useState } from "react";

import "./AdminPanel.scss";
import "../shared/css/grids.scss";
import Users from "./Users/Users";
import Companies from "./Companies/Companies";
import Subjects from "./Subjects/Subjects";
import { useTranslation } from "react-i18next";
import { SubjectsTree } from "./Subjects/SubjectsTree";
import SelectCompanyOfDepartments from "./Departments/SelectCompanyOfDepartments";

export default function AdminPanel() {
  const [selected, setSelected] = useState(0);

  const { t } = useTranslation();

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <div className="adminPanel-main">
      <h1>{t("Administration")}</h1>
      <div className="tabStrip">
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={t("Users")}>
            <Users />
          </TabStripTab>
          <TabStripTab title={t("Companies")}>
            <Companies />
          </TabStripTab>
          <TabStripTab title={t("Subjects")}>
            <SubjectsTree />
          </TabStripTab>
          <TabStripTab title={t("Departments")}>
            <SelectCompanyOfDepartments />
          </TabStripTab>
        </TabStrip>
      </div>
    </div>
  );
}
