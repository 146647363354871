import { Stepper } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { GetRequestChangeDates } from "../shared/apiService";
import { RequestDto } from "../shared/sharedModel";

const RequestStepper = ({ requestBody }: { requestBody: RequestDto }) => {
  const RequestStatuses = [
    { id: 0, label: "Submitted" },
    { id: 1, label: "Reviewing" },
    { id: 2, label: "Estimated" },
    { id: 3, label: "Accepted" },
    { id: 4, label: "In progress" },
    { id: 5, label: "Awaiting feedback" },
    { id: 6, label: "Finished" },
    { id: 7, label: "Closed" },
    { id: 8, label: "Revision" },
    { id: 9, label: "Rejected by tractebel" },
    { id: 10, label: "Rejected by client" },
  ];

  const [statuses, setStatuses] = useState(RequestStatuses);

  useEffect(() => {
    requestChangeDates.refetch();
  }, [requestBody]);

  const handleStatuses = (data: any) => {
    const newStatuses = statuses;
    data.forEach((change: any) => {
      let newStatus = RequestStatuses.find(
        (status) => status.id === change.changedTo
      );
      if (newStatus) {
        newStatus.label =
          newStatus.label +
          "\n" +
          new Date(change.createAt).toLocaleString("fr-BE");
        newStatuses[newStatus.id] = newStatus;
      }
    });
    setStatuses(newStatuses);
  };

  const requestChangeDates = useQuery(
    ["get-changes", requestBody.id],
    () => GetRequestChangeDates(requestBody.id!),
    {
      onSuccess: (data) => {
        handleStatuses(data);
      },
    }
  );

  return (
    <Stepper
      value={requestBody.setStatusDto?.currentStatus!}
      items={statuses}
    />
  );
};

export default RequestStepper;
