import { State } from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";

import "../AdminPanel.scss";
import "../../shared/css/grids.scss";
import { SubjectsDropDownCell } from "./SubjectsDropDownCell";
import { useMutation, UseQueryResult } from "react-query";
import { AddSubject, UpdateSubject } from "../../shared/apiService";
import { CompanyDto, SubjectDto } from "../../shared/sharedModel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SubjectForm } from "./SubjectForm";

const initialDataState: State = {
  sort: [],
  take: 100,
  skip: 0,
};

const Subjects = ({
  subjectsToShow,
  subjectsQuery,
  selectedCompany,
}: {
  subjectsToShow: SubjectDto[];
  subjectsQuery: UseQueryResult<any, unknown>;
  selectedCompany: CompanyDto;
}) => {
  const [subjectsDataState, setSubjectsDataState] =
    useState<any>(initialDataState);

  const [subjectsData, setSubjectsData] =
    useState<SubjectDto[]>(subjectsToShow);

  const [visibleNewSubjectDialog, setVisibleNewSubjectDialog] =
    useState<boolean>(false);
  const [editedSubject, setEditedSubject] = useState<any>();

  const { t } = useTranslation();

  const editField: string = "inEdit";

  useEffect(() => {
    if (subjectsDataState) setSubjectsData(subjectsToShow);
  }, [subjectsToShow]);

  const updateSubject = useMutation(UpdateSubject, {
    onSuccess: () => {
      toast.success("Subject saved!");
      subjectsQuery.refetch();
    },
    onError: (error) => {
      toast.error(`Subject couldn't be saved: ${error}`);
    },
  });

  const addSubject = useMutation(AddSubject, {
    onSuccess: () => {
      toast.success("Subject added!");
      subjectsQuery.refetch();
    },
    onError: (error: any) => {
      toast.error(`Subject couldn't be added: ${error.response.data}`);
    },
  });

  const handleAddSubject = (newSubject: any) => {
    addSubject.mutate(newSubject);
  };

  const update = (dataItem: any) => {
    if (dataItem.subjectSpocApplicationUserId) {
      dataItem.inEdit = false;
      dataItem.subjectSpocApplicationUser = null;
      dataItem.companyId = selectedCompany.id;
      updateSubject.mutate(dataItem);
    } else {
      toast.error("Cannot save without Subject Spoc!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const CommandCell = (props: GridCellProps) => (
    <td style={props.style} className={props.className} role={"gridcell"}>
      <div style={{ marginTop: "2px", textAlign: "center" }}>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
          onClick={() => setEditedSubject(props.dataItem)}
        >
          {t("Edit")}
        </button>
      </div>
    </td>
  );

  const itemChange = (event: GridItemChangeEvent) => {
    const data = subjectsData.map((sub) =>
      sub.name === event.dataItem.name
        ? { ...sub, [event.field || ""]: event.value }
        : sub
    );
    setSubjectsData(data);
  };

  return (
    <div className="adminPanel--container users--grid">
      {visibleNewSubjectDialog && (
        <Dialog
          title={t("EditSubject")}
          onClose={() => setVisibleNewSubjectDialog(false)}
        >
          <SubjectForm
            setVisibleNewSubjectDialog={setVisibleNewSubjectDialog}
            handleAddSubject={handleAddSubject}
            handleEditSubject={update}
            selectedCompany={selectedCompany}
          />
        </Dialog>
      )}
      {editedSubject && (
        <Dialog title={t("EditSubject")} onClose={() => setEditedSubject("")}>
          <SubjectForm
            setEditedSubject={setEditedSubject}
            handleAddSubject={handleAddSubject}
            handleEditSubject={update}
            selectedCompany={selectedCompany}
            editedSubject={editedSubject}
            updateSubject={updateSubject}
          />
        </Dialog>
      )}
      <Grid
        className="users--grid"
        data={subjectsData}
        resizable={true}
        editField={editField}
        onItemChange={itemChange}
      >
        <GridToolbar>
          <div>
            <button
              title={t("AddSubject")}
              className="admin-add--button k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={() => setVisibleNewSubjectDialog(true)}
            >
              {t("AddSubject")}
            </button>
          </div>
        </GridToolbar>
        <GridColumn width="180px" field="name" title={t("Name")} />
        <GridColumn
          width="150px"
          field="subjectSpocApplicationUserId"
          title="Subject Spoc"
          cell={SubjectsDropDownCell}
        />
        <GridColumn
          width="150px"
          field="subjectOrder"
          editor="numeric"
          title={t("SortingNumber")}
        />
        <GridColumn
          width="180px"
          field="languages"
          title={t("Description(English)")}
          cell={(props) => (
            <td>{props.dataItem?.descriptions[0]?.description}</td>
          )}
        />
        <GridColumn
          width="180px"
          field="languages"
          title={t("Description(French)")}
          cell={(props) => (
            <td>{props.dataItem?.descriptions[1]?.description}</td>
          )}
        />
        <GridColumn
          width="180px"
          field="languages"
          title={t("Description(Dutch)")}
          cell={(props) => (
            <td>{props.dataItem?.descriptions[2]?.description}</td>
          )}
        />
        <GridColumn
          width="180px"
          field="languages"
          title={t("Description(Polish)")}
          cell={(props) => (
            <td>{props.dataItem?.descriptions[3]?.description}</td>
          )}
        />
        <GridColumn width="100px" field="active" title={t("Active")} />
        <GridColumn
          cell={CommandCell}
          width="100px"
          filterable={false}
          sortable={false}
          locked={true}
        />
      </Grid>
    </div>
  );
};

export default Subjects;
