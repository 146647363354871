import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { GetSubjectsOfCompany, GetSubjectSpocs } from "../../shared/apiService";
import { SubjectDto, UserDto } from "../../shared/sharedModel";
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";

export const SubjectForm = ({
  setEditedSubject,
  handleAddSubject,
  selectedCompany,
  editedSubject,
  updateSubject,
  setVisibleNewSubjectDialog,
}: any) => {
  const englishDescription = editedSubject?.descriptions.find(
    (element: any) => element?.language === "en"
  );

  const frenchDescription = editedSubject?.descriptions.find(
    (element: any) => element?.language === "fr"
  );

  const dutchDescription = editedSubject?.descriptions.find(
    (element: any) => element?.language === "nl"
  );

  const polishDescription = editedSubject?.descriptions.find(
    (element: any) => element?.language === "pl"
  );

  const [subjects, setSubjects] = useState<SubjectDto[]>();
  const [subjectSpocs, setSubjectSpocs] = useState<UserDto[]>();

  const [subjectName, setSubjectName] = useState(editedSubject?.name);
  const [subjectTrigram, setSubjectTrigram] = useState(editedSubject?.trigram);
  const [subjectSpoc, setSubjectSpoc] = useState<UserDto>(
    editedSubject?.subjectSpocApplicationUser
  );
  const [parentSubject, setParentSubject] = useState<SubjectDto>();
  const [subjectStatus, setSubjectStatus] = useState<boolean>(
    editedSubject?.active
  );
  const [englishDescriptionStatus, setEnglishDescriptionStatus] =
    useState<string>(englishDescription?.description || "");
  const [frenchDescriptionStatus, setFrenchDescriptionStatus] =
    useState<string>(frenchDescription?.description || "");
  const [dutchDescriptionStatus, setDutchDescriptionStatus] = useState<string>(
    dutchDescription?.description || ""
  );
  const [polishDescriptionStatus, setPolishDescriptionStatus] =
    useState<string>(polishDescription?.description || "");

  const { t } = useTranslation();

  const handleSubmit = (event: any) => {
    if (editedSubject) {
      const editSubject = {
        id: editedSubject?.id,
        name: subjectName,
          trigram: subjectTrigram,
          subjectSpocApplicationUserId: subjectSpoc?.id,
        subjectEntityId: parentSubject?.id,
        active: subjectStatus,
        subjectOrder: event?.subjectOrder || 1,
        companyId: selectedCompany.id,
        descriptions: [
          {
            id: englishDescription?.id,
            subjectEntityId: editedSubject?.id,
            description: englishDescriptionStatus,
            language: "en",
          },
          {
            id: frenchDescription?.id,
            subjectEntityId: editedSubject?.id,
            description: frenchDescriptionStatus,
            language: "fr",
          },
          {
            id: dutchDescription?.id,
            subjectEntityId: editedSubject?.id,
            description: dutchDescriptionStatus,
            language: "nl",
          },
          {
            id: polishDescription?.id,
            subjectEntityId: editedSubject?.id,
            description: polishDescriptionStatus,
            language: "pl",
          },
        ],
      };
      updateSubject.mutate(editSubject);
      setEditedSubject("");
    } else {
      const newSubject = {
        name: subjectName,
        trigram: subjectTrigram,
        subjectSpocApplicationUserId: subjectSpoc?.id,
        subjectEntityId: parentSubject?.id,
        active: true,
        companyId: selectedCompany.id,
        descriptions: [
          { description: englishDescriptionStatus, language: "en" },
          { description: frenchDescriptionStatus, language: "fr" },
          { description: dutchDescriptionStatus, language: "nl" },
          { description: polishDescriptionStatus, language: "pl" },
        ],
      };
      handleAddSubject(newSubject);
      setVisibleNewSubjectDialog(false);
    }
  };

  useQuery(
    ["subjectsForForm", selectedCompany.id],
    () => GetSubjectsOfCompany(selectedCompany.id),
    {
      onSuccess: (data) => {
        data.unshift({ name: "None", id: null });
        setSubjects(data);
        setParentSubject(data[0]);
      },
    }
  );

  useQuery(["subjectSpocs"], () => GetSubjectSpocs(), {
    onSuccess: (data) => setSubjectSpocs(data),
  });

  const SubjectSpockDropdown = ({
    onChange,
    data,
    textField,
    validationMessage,
    value,
    ...other
  }: FieldRenderProps) => (
    <DropDownList
      onChange={(event) => {
        setSubjectSpoc(event.value);
        onChange(event);
      }}
      data={subjectSpocs}
      value={subjectSpoc}
      textField="email"
      {...other}
    />
  );

  const ParentSubjectDropdown = ({
    onChange,
    data,
    textField,
    validationMessage,
    value,
    ...other
  }: FieldRenderProps) => (
    <DropDownList
      onChange={(event) => setParentSubject(event.value)}
      data={subjects}
      textField="name"
      dataItemKey="id"
      value={parentSubject}
      {...other}
    />
  );

  const StatusDropDown = ({
    data,
    validationMessage,
    value,
    onChange,
    ...other
  }: FieldRenderProps) => (
    <DropDownList
      data={[true, false]}
      value={subjectStatus.toString()}
      onChange={(event) => {
        setSubjectStatus(event.value);
        onChange(event);
      }}
      {...other}
    />
  );

  const OrderInput = ({ validationMessage, ...other }: FieldRenderProps) => (
    <Input
      type="number"
      defaultValue={editedSubject?.subjectOrder || 1}
      min={1}
      {...other}
    />
  );

  return (
    <div className="companyForm--main">
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement>
            <div className="companyForm--field">
              <label>{t("SubjectName")}</label>
              <Field
                name="subjectName"
                onChange={(e) => {
                  setSubjectName(e.value);
                }}
                defaultValue={editedSubject?.name}
                value={subjectName}
                component={Input}
              />
            </div>
            <div className="companyForm--field">
              <label>{t("Trigram")}</label>
              <Field
                name="subjectTrigram"
                onChange={(e) => {
                  setSubjectTrigram(e.value);
                }}
                defaultValue={editedSubject?.trigram}
                value={subjectTrigram}
                validityStyles={false}
                component={Input}
              />
            </div>
            <div className="companyForm--field">
              <label>{t("SubjectSpoc")}</label>
              <Field name="subjectSpock" component={SubjectSpockDropdown} />
            </div>
            {!editedSubject && (
              <div className="companyForm--field">
                <label style={{ marginBottom: "8px" }}>
                  {t("ParentSubject")}
                </label>
                <Field name="parentSubject" component={ParentSubjectDropdown} />
              </div>
            )}
            {editedSubject && (
              <div className="companyForm--field">
                <label>{t("Active")}</label>
                <Field name="active" component={StatusDropDown} />
              </div>
            )}
            <div className="companyForm--field">
              <label>{t("SortingNumber")}</label>
              <Field name="subjectOrder" component={OrderInput} />
            </div>
            <div className="companyForm--field">
              <label style={{ marginBottom: "8px" }}>
                {t("Description(English)")}
              </label>
              <Field
                name="descriptionEnglish"
                component={TextArea}
                value={englishDescriptionStatus}
                onChange={(event) => setEnglishDescriptionStatus(event.value)}
                defaultValue={englishDescription?.description}
              />
            </div>
            <div className="companyForm--field">
              <label style={{ marginBottom: "8px" }}>
                {t("Description(French)")}
              </label>
              <Field
                name="descriptionFrench"
                component={TextArea}
                value={frenchDescriptionStatus}
                onChange={(event) => setFrenchDescriptionStatus(event.value)}
                defaultValue={frenchDescription?.description}
              />
            </div>
            <div className="companyForm--field">
              <label style={{ marginBottom: "8px" }}>
                {t("Description(Dutch)")}
              </label>
              <Field
                name="descriptionDutch"
                component={TextArea}
                value={dutchDescriptionStatus}
                onChange={(event) => setDutchDescriptionStatus(event.value)}
                defaultValue={dutchDescription?.description}
              />
            </div>
            <div className="companyForm--field">
              <label style={{ marginBottom: "8px" }}>
                {t("Description(Polish)")}
              </label>
              <Field
                name="descriptionPolish"
                component={TextArea}
                value={polishDescriptionStatus}
                onChange={(event) => setPolishDescriptionStatus(event.value)}
                defaultValue={polishDescription?.description}
              />
            </div>
            <div className="companyForm--field">
              <Button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              >
                {editedSubject ? t("Update") : t("Create")}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};
